import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  withStyles
} from "@material-ui/core";
import React from "react";
import handImage from "../../assets/images/hand.webp";
import newWebsite11 from "../../assets/images/new-website-11.webp";
import newWebsite13 from "../../assets/images/new-website-13.webp";
import newWebsite12 from "../../assets/images/new-website-12.webp";
import newWebsite08 from "../../assets/images/new-website-08.webp";
import newWebsite01 from "../../assets/images/new-website-01.webp";
import newWebsite15 from "../../assets/images/new-website-15.webp";
import newWebsite09 from "../../assets/images/new-website-09.webp";
import newWebsite06 from "../../assets/images/new-website-06.webp";
import newWebsite22 from "../../assets/images/new-website-22.webp";
import newWebsite23 from "../../assets/images/new-website-23.webp";
import Layout from "../../components/Layout";

const styles = (theme) => ({
  imgContainer: {
    position: "relative"
  },
  titleText: {
    position: "absolute",
    color: "#ffffff",
    fontFamily: "Proxima Nova",
    fontWeight: "Bold",
    fontSize: "3.5vw",
    top: "86%",
    left: "50%",
    width: "100%",
    transform: "translateX(-50%)",
    [theme.breakpoints.up('lg')]: {
      fontSize: "40px !important"
    },
  },
  administrationImg: {
    display: "block",
    width: "100%"
  },
  featureContainer: {
    marginTop: "3rem",
    marginBottom: "3rem",
    justifyContent: "center",
    [theme.breakpoints.only('xs')]: {
      margin: '1rem 0rem',
    },
  },
  card: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 20%",
    borderRadius: "none !important",
    boxShadow: "none !important"
  },
  leftCard: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10% 0px 15%",
    borderRadius: "none !important",
    boxShadow: "none !important",
    justifyContent: "center",
    [theme.breakpoints.only('xs')]: {
      height: "120px"
    },
    "@media (max-width:960px)": {
      padding: "0px 20%"
    }
  },
  rightCard: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 15% 0px 10%",
    borderRadius: "none !important",
    boxShadow: "none !important",
    justifyContent: "center",
    [theme.breakpoints.only('xs')]: {
      height: "120px"
    },
    "@media (max-width:960px)": {
      padding: "0px 20%"
    }
  },
  img: {
    height: "100px",
    width: "137px",
    objectFit: "contain",
    [theme.breakpoints.only('xs')]: {
      height: "50px !important",
      width: "68.84px",
    },
  },
  imgBox: {
    display: "flex",
    alignItems: "center"
  },
  logoRezoomex: {
    position: "absolute",
    color: "white",
    top: "2%",
    left: "42%",
    width: "80%",
    transform: "translateX(-50%)",
    fontSize: "4.5vw",
    height: "auto",
    [theme.breakpoints.only('xs')]: {
      marginTop: "-2px !important"
    },
  },
  buttonsContainer: {
    position: "absolute",
    color: "white",
    top: "2%",
    left: "94%",
    transform: "translateX(-50%)",
    fontSize: "1vw",
    width: "10%",
    height: "5%",
    display: "flex",
    flexDirection: "row",
    backgroundColor: '#999',
    alignItems: "center",
    borderRadius: "8px",
    justifyContent: "center",
    [theme.breakpoints.up('lg')]: {
      fontSize: "12px !important"
    },
    [theme.breakpoints.only('xs')]: {
      display: "none !important"
    },
  },
  buttonsStyle: {
    textDecoration: "none",
    color: "#fff",
    padding: "5%"
  },
  divider: {
    backgroundColor: "white !important"
  },
  pageHeader: {
    height: "50px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "white",
    [theme.breakpoints.up('sm')]: {
      display: "none !important"
    },
  },
  mainGridItem: {
    display: 'flex',
  },
  gridTextContainer: {
    width: "100%",
    display: 'grid',
    justifyContent: "center"
  },
  LinksComponentClass: {
    [theme.breakpoints.only('xs')]: {
      display: "none !important"
    },
  },
  boxContent: {
    [theme.breakpoints.only('xs')]: {
      width: "180px",
      fontSize: "12px"
    },
  },
  boxContentContainer: {
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: "center"
    },
  }
});

const ApplicantTracking = (props) => {
  const { classes } = props;

  return (
    <Layout 
      horeImage={newWebsite22} 
      title="Ethical candidates, Intuitive search, prioritized applicant tracking, immediate smart contracts."
      pageTitle="Rezoomex's Applicant Tracking System"
      pageDescription="Rezoomex's game-changing tool that puts ethical candidates first. Offers intuitive search capabilities, prioritizes applicant tracking and enables instant smart contracts. Elevate your hiring process with efficiency, integrity and seamless talent acquisition."      
      pageKeywords="ATS, Hiring tool, Tech hiring, Applicant tracking system, Rezoomex, Talent acquisition tool, Ethical hiring"
    >
      <Grid container spacing={0} className={classes.featureContainer}>
        <Grid item lg={6} md={6} sm={12} sx={12} className={classes.gridItem}>
        <Card className={classes.leftCard}>
            <Box className={classes.imgBox}>
              <img src={handImage} className={classes.img} alt="test" />
            </Box>
            <Box className={classes.boxContentContainer}>
              <CardContent>
                <Typography align="left" className={classes.boxContent}>
                Let availability not be the primary criterion for selection. Source candidates from the Rezoomex NFT Marketplace. They adhere to professional ethics and will honor their commitment to join.
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Card className={classes.leftCard}>
            <Box className={classes.imgBox}>
              <img src={newWebsite11} className={classes.img} alt="test" />
            </Box>
            <Box className={classes.boxContentContainer}>
              <CardContent>
                <Typography align="left" className={classes.boxContent}>
                Excellent candidate experience- timely alerts, interview feedback, tips for improvement with study material - great for employer branding and offer to joining conversion.
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Card className={classes.leftCard}>
            <Box className={classes.imgBox}>
              <img src={newWebsite13} className={classes.img} alt="test" />
            </Box>
            <Box className={classes.boxContentContainer}>
              <CardContent>
                <Typography align="left" className={classes.boxContent}>
                Ability to set/update priority of job requisitions – which focuses the recruitment effort on critical needs
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Card className={classes.leftCard}>
            <Box className={classes.imgBox}>
              <img src={newWebsite12} className={classes.img} alt="test" />
            </Box>
            <Box className={classes.boxContentContainer}>
              <CardContent >
                <Typography align="left" className={classes.boxContent}>
                Dashboards to monitor progress, alert concerned users, gain valuable insights, and forecast outcomes.
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Card className={classes.leftCard}>
            <Box className={classes.imgBox}>
              <img src={newWebsite08} className={classes.img} alt="test" />
            </Box>
            <Box className={classes.boxContentContainer}>
              <CardContent>
                <Typography align="left" className={classes.boxContent}>
                No cumbersome data-entry. Excellent parser converts any unstructured resume into a well-structured record.
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={12} sx={12}>
          {/* <Item>test 2</Item> */}
          <Card className={classes.rightCard}>
            <Box className={classes.imgBox}>
              <img src={newWebsite23} className={classes.img} alt="test" />
            </Box>
            <Box className={classes.boxContentContainer}>
              <CardContent>
                <Typography align="left" className={classes.boxContent}>
                Conclude your talent search with smart contracts to get immediate results. If you like the candidate seal the deal right there – it takes just a few clicks.
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Card className={classes.rightCard}>
            <Box className={classes.imgBox}>
              <img src={newWebsite01} className={classes.img} alt="test" />
            </Box>
            <Box className={classes.boxContentContainer}>
              <CardContent>
                <Typography align="left" className={classes.boxContent}>
                AI aided, yet transparently computed suitability score-based ranking of candidates with an easy-to-understand justification.
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Card className={classes.rightCard}>
            <Box className={classes.imgBox}>
              <img src={newWebsite15} className={classes.img} alt="test" />
            </Box>
            <Box className={classes.boxContentContainer}>
              <CardContent>
                <Typography align="left" className={classes.boxContent}>
                Intuitive UI designed to help the recruiters, candidates and interviewers to track and receive their rewards and encash Rezo$ tokens. 
                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Card className={classes.rightCard}>
            <Box className={classes.imgBox}>
              <img src={newWebsite09} className={classes.img} alt="test" />
            </Box>
            <Box className={classes.boxContentContainer}>
              <CardContent>
                <Typography align="left" className={classes.boxContent}>
                Facilitates collaboration between candidates, recruiters, interviewers, and agencies.

                </Typography>
              </CardContent>
            </Box>
          </Card>
          <Card className={classes.rightCard}>
            <Box className={classes.imgBox}>
              <img src={newWebsite06} className={classes.img} alt="test" />
            </Box>
            <Box className={classes.boxContentContainer}>
              <CardContent>
                <Typography align="left" className={classes.boxContent}>
                Real time history of all events pertaining to a job
                  requisition, or a candidate, viewable at a glance.
                </Typography>
              </CardContent>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Layout >
  );
};

export default withStyles(styles, { withTheme: true })(ApplicantTracking);
