import React from "react";
import { Divider, Grid, Typography, withStyles } from "@material-ui/core";
import LinksComponent from "./LinksComponent";
import administration from "../../assets/images/Administration.jpg";
import Footer from "./Footer";
import RezoomexLogo from "./RezoomexLogo";
import Cube from "../../assets/images/Cube2.png";
import MenuComponent from "./MenuComponent";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  imgContainer: {
    position: "relative"
  },
  administrationImg: {
    display: "block",
    width: "100%"
  },
  titleText: {
    position: "absolute",
    fontFamily: "Proxima Nova",
    fontWeight: "Bold",
    fontSize: "4.5vw",
    top: "10%",
    left: "50%",
    width: "100%",
    transform: "translateX(-50%)",
    [theme.breakpoints.up('lg')]: {
      fontSize: "49px !important"
    },
    [theme.breakpoints.only('xs')]: {
      top: "60px"
    },
  },
  buttonCubeContainer: {
    justifyContent: "center",
    [theme.breakpoints.only('xs')]: {
      marginTop: '20px'
    },
  },
  buttonCube: {
    margin: "1rem",
    display: "flex",
    justifyContent: "center"
  },
  subtitle: {
    fontWeight: "bold",
    margin: "3rem 4rem 2rem 4rem",
    [theme.breakpoints.only('xs')]: {
      fontSize: "15px",
      margin: "15px"
    }
  },
  logoRezoomex: {
    position: "absolute",
    color: "white",
    top: "2%",
    left: "42%",
    width: "80%",
    transform: "translateX(-50%)",
    fontSize: "4.5vw",
    height: "auto",
    [theme.breakpoints.only('xs')]: {
      marginTop: "-2px !important"
    },
  },
  pageHeader: {
    height: "50px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "white",
    [theme.breakpoints.up('sm')]: {
      display: "none !important"
    },
  },
  mainGridItem: {
    display: 'flex',
  },
  gridTextContainer: {
    width: "100%",
    display: 'grid',
    justifyContent: "center"
  },
  LinksComponentClass: {
    [theme.breakpoints.only('xs')]: {
      display: "none !important"
    },
  },
  cubeButton: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  cubeImg: {
    height: "130px",
    width: "149px",
    [theme.breakpoints.only('xs')]: {
      height: "70px",
      width: "70px"
    },
  },
  cubeText: {
    position: "absolute",
    width: "100px",
    [theme.breakpoints.only('xs')]: {
      fontSize: "8px",
      width: "35px !important"
    }
  }
});

const Administration = (props) => {
  const { classes } = props;

  return (
    <div>
      <Grid container>
        <Grid
          item
          className={classes.imgContainer}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <div className={classes.pageHeader}>
            <MenuComponent />
          </div>
          <img
            src={administration}
            className={classes.administrationImg}
            alt="administration"
          />
          <Typography className={classes.titleText} variant="h2">
            Administration
          </Typography>
          <div className={classes.logoRezoomex}>
            <RezoomexLogo />
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.LinksComponentClass}>
          <LinksComponent />
        </Grid>
      </Grid>

      <Typography variant="h5" className={classes.subtitle}>
        Consortium members get full access, other companies get partial access
        to admin functions
      </Typography>

      <Grid container className={classes.buttonCubeContainer}>
        {/* <Grid container className={classes.buttonCubeContainer}> */}
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Manage Users</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Change incentive</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Email templates</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>SMS templates</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>View events</label>
          </div>
        </Grid>

        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Get API key</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Configure rules</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Schedule jobs</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Confusion matrix</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Manage ontology</label>
          </div>
        </Grid>

        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Manage Gazetteer</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Restart service</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Message queue</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Zipkin</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Cost analysis</label>
          </div>
        </Grid>

        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Rezo$ pool</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Caliber$ pool</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Smart contracts</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Artefact repository</label>
          </div>
        </Grid>
        <Grid item className={classes.buttonCube}>
          <div className={classes.cubeButton}>
            <img src={Cube} className={classes.cubeImg} alt="Cube Button"></img>
            <label className={classes.cubeText}>Storage backup</label>
          </div>
        </Grid>
        {/* </Grid> */}
      </Grid>
      {/* <div style={{ marginBottom: "2rem" }}></div> */}
      <Footer />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Administration);
