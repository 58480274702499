import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import EarnHandsomeReturnBg from '../../assets/images/EarnHandsomeReturn.webp';
import Layout from '../../components/Layout';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    bgContentText: {
        position: "absolute",
        color: "#000000",
        top: "15%",
        left: "65%",
        transform: "translateX(-50%)",
        fontSize: "3.7vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "Bold",
        [theme.breakpoints.up('lg')]: {
            fontSize: "45px !important",
        },
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    buttonsContainer: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "94%",
        transform: "translateX(-50%)",
        fontSize: "1vw",
        width: "10%",
        height: "5%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: '#999',
        alignItems: "center",
        borderRadius: "8px",
        justifyContent: "center",
        [theme.breakpoints.up('lg')]: {
            fontSize: "12px !important"
        },
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonsStyle: {
        textDecoration: "none",
        color: "#fff",
        padding: "5%"
    },
    divider: {
        backgroundColor: "white !important"
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        textIndent: "50px",
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            textIndent: "25px",
            marginBottom: "5px",
        },
    },
    titleText: {
        fontSize: "2.7vw",
        fontWeight: "600",
        [theme.breakpoints.up('lg')]: {
            fontSize: "35px !important",
        },
    }
})

const EarnHandsomeReturn = (props) => {

    const { classes } = props;

    return (
        <Layout 
            horeImage={EarnHandsomeReturnBg} 
            title="You worked hard. You built your resume. Now, use it to earn some passive income and the autonomy to work from anywhere!"
            pageTitle="CVs as source of income | Rezoomex"
            pageDescription="Discover how your Resume will help you earn passive income. Mint your resume and host it at Rezoomex gig work platform. Also find interesting side projects."
            pageKeywords="Passive income, Resume as income source, CVs as income source, Gig Economy, Gig work platform, gig work, Rezoomex, Remote work"
        >
            <div className={classes.pageContent}>
                <Typography className={classes.pageParaText}>Your resume is your most valuable asset. Think of the time and effort spent chipping away, blow by blow, to sculpt it. Here's a way to put this asset to work even when you aren't actively looking for a change. Just mint your resume NFT and host it on the Rezoomex NFT marketplace. You will receive a bunch of goodies, including your favorite nickname, a cool picture of your avatar, and Caliber$ tokens. However, the real value lies in the international smart contracts that allow you to work on exciting projects – all of them enable you to work from home. Forget about going to work, because work will come to you! The world is moving towards becoming a gig economy. Get ready for it!</Typography>
                <Typography className={classes.pageParaText}>What happens to your full-time employment? You can continue if you are happy, but eventually, plan to become a gig worker. No stealthy moonlighting – do it with pride. Achieve the autonomy and independence to do what you love. But how can you ensure continuity of income? Here's how- Your resume will start earning even before your first smart contract. There are many ways to earn once your resume NFT is on the marketplace. The easiest one is by choosing to remain anonymous. Every time you allow an interested company to know your identity, you will receive a small amount. This is in complete compliance with the recently enacted “Digital Personal Data Protection Act”. No one can access your personal data without your consent.</Typography>
                <Typography className={classes.pageParaText}>Your Caliber$ tokens, as suggested by the name, quantify your skills, experience, and expertise. You can stake these tokens on deserving fellow professionals. Similarly, others will stake their Caliber$ on you, thus building your reputation that will help you earn more. Even without that, your staked Caliber$ will earn some passive income whenever the professional on whom it's staked earns. These sources of passive income will grow as you add to your skills and expertise.</Typography>
                <Typography className={classes.pageParaText}>Sounds good? All you need to do is take an oath to follow professional ethics and upload your latest resume. Why the oath? We believe that we need to follow ethics to deserve autonomy. Our professionals are honest, truthful, and ethical – they don't need any oversight or supervision because they are truly self-managing, self-organizing, and self-made professionals.</Typography>
            </div>
        </Layout>
    )
}

EarnHandsomeReturn.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(EarnHandsomeReturn);