import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import VirtualOathBg from '../../assets/images/virtualOath.webp';
import Layout from '../../components/Layout';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    pageTitle: {
        textAlign: "left !important",
        fontWeight: "bold",
        fontSize: "20px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px !important",
            fontWeight: 'Bold !important',
            textAlign: "center !important"
        },
    },
    gridItem: {
        display: "flex !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important",
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    buttonsContainer: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "94%",
        transform: "translateX(-50%)",
        fontSize: "1vw",
        width: "10%",
        height: "5%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: '#999',
        alignItems: "center",
        borderRadius: "8px",
        justifyContent: "center",
        [theme.breakpoints.up('lg')]: {
            fontSize: "12px !important"
        },
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonsStyle: {
        textDecoration: "none",
        color: "#fff",
        padding: "5%"
    },
    divider: {
        backgroundColor: "white !important"
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        marginBottom: "0px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            marginBottom: "5px",
        },
    },
    bgContentText: {
        position: "absolute",
        fontWeight: "bold",
        fontFamily: "Proxima Nova",
        fontSize: "4vw",
        top: "8%",
        left: "50%",
        width: "100%",
        transform: "translateX(-50%)",
        [theme.breakpoints.up('lg')]: {
            fontSize: "40px !important",
        },
    },
    titleText: {
        fontSize: "3.9vw",
        fontWeight: "500",
        marginTop: "27%",
        color: '#FFFFFF',
        [theme.breakpoints.up('lg')]: {
            fontSize: "35px !important",
            marginTop: "29%",
        },
    },
    gridFormContainer: {
        width: '770px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '0px 40px 0px 40px !important',
        },
        [theme.breakpoints.only('xs')]: {
            margin: "0px 0px 0px 0px !important",
        },
    },
    gridItems: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
            paddingBottom: '15px'
        },
    },
    formButton: {
        backgroundColor: '#4A90E2 !important',
        color: '#FFFFFF !important',
        width: '320px !important',
        padding: '12px 10px',
        textTransform: 'none',
        fontSize: '18px'
    },
    formFixed: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingCircle: {
        zIindex: 5011,
        position: "fixed",
        padding: "0px",
        margin: "0px",
        top: "40%",
        textAlign: "center",
        border: "none",
        cursor: "default",
        opacity: 0.7,
        backgroundColor: "transparent",
    }
})

const Oath = (props) => {

    const { classes } = props;

    const openLink = () => {
        window.open("https://ranker.rezoomex.com/referral-link/63d7732eb50d5b00013ebc5f/?platform=NFT", '_blank')
    }

    return (
        <Layout 
            horeImage={VirtualOathBg} 
            title="Community of ethical, autonomous software professionals"
            pageTitle="Ethics in IT Industry, Take Oath | Rezoomex"
            pageDescription="Rezoomex takes great pride in developing ethics in Indian IT Industry. Take Oath now to become a member of an ethical community."
            pageKeywords="Rezoomex, Rezoomex Gig Work Platform, Ethics in IT Industry, Community of ehtical employees, Ethics in Indian IT industry, Oath for IT professional, Honesty, Moonlighting, Proxy Interviews, Oath"
        >
            <div>
                <Typography className={classes.pageParaText}><strong>I solemnly pledge</strong> to uphold the highest standards of honesty, truthfulness, and integrity as an Indian Software professional. I take great pride in being a member of a profession that has contributed significantly to our country's growth and prosperity.</Typography>
                <Typography className={classes.pageParaText}><strong>I understand</strong> that working remotely from home or elsewhere comes with higher responsibility of practicing transparency, honesty, and professional ethics to win the trust of employers and clients.</Typography>
                <Typography className={classes.pageParaText}><strong>I recognize</strong> that unethical practices such as proxy interviews, moonlighting, no show, absconding, and quiet quitting can tarnish the image of our profession. Therefore, I pledge to be vigilant against such practices and actively work towards eradicating those from our industry to create the trust needed to increase the adoption of remote working, self-organization, flexibility, and autonomy.</Typography>
                <Typography className={classes.pageParaText}><strong>I pledge</strong> to actively participate in this community’s discussions to evolve the code of conduct to be followed by everyone. In cases where there is no consensus, I will continue to debate and work towards creating a consensus-based solution.</Typography>
                <Typography className={classes.pageParaText}><strong>I understand</strong> that my actions and behaviour reflect not only on me but on my entire profession. Therefore, I will always strive to act in a manner that upholds the highest standards of professionalism and integrity, both in my personal and professional life.</Typography>
                <Typography className={classes.pageParaText}><strong>As a symbol</strong> of my commitment to this oath, I am submitting my resume- note that it represents not just my skillset, but my dedication to upholding the highest ethical standards in my profession.</Typography>
                <br></br>
                <Button
                    variant="contained"
                    color='primary'
                    className={classes.formButton}
                    onClick={() => openLink()}
                >
                    Sanctified by uploading my resume
                </Button>
            </div>
        </Layout>
    )
}

Oath.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Oath);