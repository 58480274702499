import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import RemoteFirstImage from '../../assets/images/remoteFirst.webp';
import Layout from '../../components/Layout';
import { Button } from '@mui/material';
import config from '../../config';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    bgContentText: {
        position: "absolute",
        color: "#000000",
        top: "15%",
        left: "65%",
        transform: "translateX(-50%)",
        fontSize: "3.7vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "Bold",
        [theme.breakpoints.up('lg')]: {
            fontSize: "45px !important",
        },
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    buttonsContainer: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "94%",
        transform: "translateX(-50%)",
        fontSize: "1vw",
        width: "10%",
        height: "5%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: '#999',
        alignItems: "center",
        borderRadius: "8px",
        justifyContent: "center",
        [theme.breakpoints.up('lg')]: {
            fontSize: "12px !important"
        },
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonsStyle: {
        textDecoration: "none",
        color: "#fff",
        padding: "5%"
    },
    divider: {
        backgroundColor: "white !important"
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            marginBottom: "5px",
        },
    },
    titleText: {
        fontSize: "2.7vw",
        fontWeight: "600",
        [theme.breakpoints.up('lg')]: {
            fontSize: "35px !important",
        },
    }
})

const RemoteFirst = (props) => {

    const { classes } = props;

    const openCreateNft = () => {
        window.open(`${config.marketplaceUrl}create-nft`, '_blank', 'noreferrer');
    }

    return (
        <Layout 
            horeImage={RemoteFirstImage} 
            title={
            <Typography style={{ fontSize: "unset", fontFamily: "unset", fontWeight: "unset" }}>I have stopped going to work. Work comes to me.<br></br>Thanks to Rezoomex!</Typography>}
            pageTitle="Remote jobs in IT | Rezoomex"
            pageDescription="Rezoomex is a gig work platform which promotes remote work. We adhere to professional ethics among Indian software engineers and deliver value to employers."
            pageKeywords="Remote work, Remote jobs, Ethics in remote work, working remotely, Rezoomex and remote work, Rezoomex gig work platform, Side projects, Avatar, Ethical community. Remote employers, Remote employees"
        >
            <div className={classes.pageContent}>
                <Typography className={classes.pageParaText}>Rezoomex is a platform that promotes remote work while adhering to professional ethics among Indian software engineers. We champion the ethical delivery of value to employers and customers while working from home. Rezoomex is designed for software engineers who voluntarily follow a specific code of conduct. We are conscientious, well-engaged, honest, and truthful by habit. We don't require supervision, and we are self-sufficient in terms of home infrastructure. We cherish our autonomy and choose to work from home or wherever we please.</Typography>
                <Typography className={classes.pageParaText}>We save time and money that would otherwise be spent on commuting to work. We invest that time in improving our lives. In addition to exercise, sleep, and family time, we also focus on developing our skills to prepare for the future. Rezoomex empowers us.</Typography>
                <Typography className={classes.pageParaText}>We don't fear losing our jobs because we have built a steady source of income by working on side projects and staking our Caliber$ on others in the Rezoomex NFT marketplace. Our basic needs are covered by our baseline income.</Typography>
                <Typography className={classes.pageParaText}>We take pride in being part of the Indian fintech revolution driven by UPI payments, CBDC, smart contracts and DPDP Act. Our personal data is never shared without our consent.</Typography>
                <Typography className={classes.pageParaText}>If you are one of us, please join us by taking an oath and uploading your resume. We will provide you with a unique identity by allowing you to choose a stylish "Avatar" or NFT. This comes with numerous benefits, and it's completely free! The primary advantage is the opportunity to work on cutting-edge projects directly with end customers, most of them are from the US. Earn dollar income without having to go anywhere. Get started by clicking here.</Typography>
                <Button onClick={() => openCreateNft()} variant="contained" color="primary">
                    Create NFT
                </Button>

            </div>
        </Layout>
    )
}

RemoteFirst.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(RemoteFirst);