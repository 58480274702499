import React from 'react';
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardMedia, Divider, Typography } from '@material-ui/core';
import Workingremotely from '../../assets/images/Workingremotely.webp';
import LinksComponent from './LinksComponent';
import RezoomexLogo from './RezoomexLogo';
import Footer from './Footer';
import MenuComponent from './MenuComponent';
import { Link } from 'react-router-dom';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    pageTitle: {
        textAlign: "left !important",
        fontWeight: "bold",
        fontSize: "20px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px !important",
            fontWeight: 'Bold !important',
            textAlign: "center !important"
        },
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },
    gridItem: {
        display: "flex !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important",
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonCardContainer: {
        [theme.breakpoints.only('xs')]: {
            marginTop: "10px"
        },
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        textIndent: "50px",
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            textIndent: "25px",
            marginBottom: "5px",
        },
    },
    bgContentText: {
        position: "absolute",
        fontWeight: "bold",
        fontFamily: "Proxima Nova",
        fontSize: "2.9vw",
        top: "4%",
        left: "50%",
        width: "100%",
        transform: "translateX(-50%)",
        [theme.breakpoints.up('lg')]: {
          fontSize: "40px !important",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "2.5vw",
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: "2.3vw",
        },
      },
    userDetailsLink: {
        textDecoration: "none",
        color: "#3298CC",
    },
    titleText: {
        fontSize: "2.5vw",
        fontWeight: "500",
        marginTop: "1%",
        [theme.breakpoints.up('lg')]: {
            fontSize: "35px !important",
        },
    }
})

const WorkingRemotely = (props) => {

    const { classes } = props;

    return (
        <div >
            <div>
                <div>
                    <Grid container>
                        <Grid container sx={{ position: 'relative' }}>
                            <Grid item lg={12} sm={12} md={12} xs={12} >
                                <Card className={classes.root}>
                                    <div className={classes.pageHeader}>
                                        <MenuComponent />
                                    </div>
                                    <div className={classes.bgImageContainer}>
                                        <CardMedia className={classes.bgImg} component="img" image={Workingremotely} alt="Working Remotely" />
                                        <div className={classes.bgContentText}>
                                            Monkey business in your remote teams?                                        <div className={classes.titleText}>
                                                You can prevent it, with our deterrent
                                            </div>
                                        </div>
                                        <div className={classes.logoRezoomex}>
                                            <RezoomexLogo />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className={classes.LinksComponentClass}>
                <Grid container >
                    <Grid container>
                        <Grid item lg={12} sm={12} md={12} xs={12}>
                            <LinksComponent />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.leftRightContainer} >
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className={classes.pageContent}>
                            <div className={classes.pageContent}>
                                <Typography className={classes.pageParaText}>Remote working is here to stay. It comes with its bag of issues particularly if the remote workers are in India. Attrition was always a problem. There’s no certainty that a candidate will join on the committed date. There are other issues like moonlighting, bait and switch, proxy interviews. Some teams have issues of engagement ranging from muted video calls to the team member totally absconding. </Typography>
                                <Typography className={classes.pageParaText}>You are fortunate if it’s not happening in your India based teams. But, it could happen in the future. So why not get this deterrent - it’s free. All you need to do is to click on <Link to={"/userdetails"} className={classes.userDetailsLink}>this link</Link> and follow instructions.</Typography>
                                <Typography className={classes.pageParaText}>You will be able to reward your teams or penalize them to get the right behavior. So, you might see a dramatic improvement in the overall performance. Moreover, you will see it happening consistently with predictability. </Typography>
                                <Typography className={classes.pageParaText}>You will benefit from the community with thousands of years of cumulative experience in dealing with complex team issues. Try it!</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    )
}

WorkingRemotely.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(WorkingRemotely);