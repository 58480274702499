import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import LearningearningImage from '../../assets/images/Learningearning.png';
import Layout from '../../components/Layout';
import { Box } from '@mui/material';

const styles = theme => ({
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },
    gridItem: {
        display: "flex !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            marginBottom: "5px",
        },
    },
    userDetailsLink: {
        textDecoration: "none",
        color: "#3298CC",
    },
    listPoint: {
        color: "var(--primary-color)"
    },
    listPointDetails: {
        color: "#000"
    }
})

const Accomplishments = (props) => {

    const { classes } = props;

    return (
        <Layout 
            horeImage={LearningearningImage} 
            title="Rewarding, enlightening, enjoyable gig work."
            pageTitle="Rezoomex Gig Work Platform"
            pageDescription="Rezoomex is a gig work platform where companies and tech professionals engage in smart contracts. Find side projects, achieve autonomy and build a new source of income."
            pageKeywords="Gig work, Side projects, Gig economy, Gig work platform, Short tasks, Rezoomex, Rezoomex gig work platform, Extra source of income"
        >
            <div className={classes.pageParaText}>
                <Typography className={classes.pageParaText}>Companies and professionals can engage in smart contracts to create additional value for both. There's no need to know each other, thanks to the inherent safety of smart contracts. When the
                    work is completed, smart contracts seamlessly trigger payments – thanks to India’s UPI payments and CBDC. This opens up a whole new world of opportunities. The Rezoomex NFT marketplace actively sources interesting projects and tech tasks to help you earn money and Caliber$.</Typography>
                <Typography component='div' className={classes.pageParaText}>Professionals can achieve autonomy and independence by ensuring a baseline income to support themselves between jobs in case of such an eventuality. The following types of projects and tech tasks can help them build a steady source of income and learning:
                <Box m={2}>
                <ul>
                        <li>2-minute tasks like re-posting messages on social media.</li>
                        <li>Short tasks like conducting an interview or attending a call.</li>
                        <li>Referral – candidates or customers. Talent mining or lead generation.</li>
                        <li>Lending credibility – allowing a company to use your name.</li>
                        <li>Conducting a training program for a specific technology.</li>
                        <li>Creating a technical assessment quiz.</li>
                        <li>Curating or creating interesting and educative content.</li>
                        <li>Book appreciation discussions specific to a technology.</li>
                        <li>Running boot camps/ dojos.</li>
                        <li>Organizing coding competitions.</li>
                    </ul>
                    </Box>
                </Typography>
                <Typography className={classes.pageParaText}>These projects or tech tasks can be undertaken with little to no conflict of interest with the primary employer. In a recent survey most employers supported projects that would enhance their employees’ expertise and skills as long as it didn’t impact performance, deliverables, and level of engagement.</Typography>
            </div>

        </Layout>
    );
}

Accomplishments.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Accomplishments);