const config = {
    apiGatewayEndpoint: '{{REZOOMEX_API_GATEWAY_ENDPOINT}}',
    marketplaceUrl: '{{MARKETPLACE_URL}}',
    communityMembers: '{{COMMUNITY_MEMBERS}}',
    mintedMembers: '{{MINTED_MEMBERS}}',
    oathMembers: '{{OATH_MEMBERS}}',
    marketplaceApp: '{{MARKETPLACE_APP}}',
    companyName: '{{NEXT_PUBLIC_COMPANY_NAME}}',
    companyAddress: '{{NEXT_PUBLIC_COMPANY_ADDRESS}}',
    companyEmail: '{{NEXT_PUBLIC_COMPANY_EMAIL}}',
    companyNumber: '{{NEXT_PUBLIC_COMPANY_NUMBER}}',
    lastUpdatedDate: '{{NEXT_PUBLIC_LAST_UPDATED_DATE}}'
  }
  
  export default config