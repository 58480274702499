import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Box } from "@mui/material";
import RezoomexLogo from "./RezoomexLogo";
import Footer from "./Footer";
import config from "../../config";

const styles = (theme) => ({
  pageParaText: {
    textAlign: "left",
    padding: "10px",
    marginBottom: "10px",
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.75rem",
      marginBottom: "5px",
    },
  },
  padding_5: {
    padding: "5px 0px",
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    padding: "10px",
    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.2)",
    width: "100%",
  },
  boxStyle: {
    padding: "0px 1rem",
  },
  ol: {
    textAlign: "left",
  },
  textIndent: {
    textIndent: "0.2rem",
  },
  boxStyleInner: {
    margin: "0px 0px 0px 20px !important",
    padding: "0px",
  },
  ul: {
    textIndent: "0.2rem",
    listStyleType: "disc",
  },
  pageTitle: {
    marginTop: "10px",
  },
});

const PrivacyPolicy = (props) => {
  const { classes } = props;

  return (
    <>
      <div className={classes.header}>
        <RezoomexLogo />
      </div>
      <div className={classes.pageParaText}>
        <Typography className={classes.pageTitle}>
          <strong>Privacy Policy</strong>
        </Typography>
        <Typography>Last Updated: {config.lastUpdatedDate}</Typography>
      </div>
      <div className={classes.pageParaText}>
        <Typography>
          <strong>Introduction</strong>
        </Typography>
        <Typography>
          Welcome to Rezoomex! We highly value your privacy and are dedicated to
          safeguarding any personal information you share with us. Rezoomex is a
          product of Alphonso Informex Inc., and this Privacy Policy explains
          how we gather, use, and protect your data when you use our services,
          visit our website, or interact with us in any other way. While
          Alphonso Informex Inc. is the parent company, Rezoomex operates
          independently as a company. By accessing Rezoomex’s services or
          website, you acknowledge and agree to the terms outlined in this
          Privacy Policy.
        </Typography>
      </div>
      <div>
        <Box m={2} className={classes.boxStyle}>
          <ol className={classes.ol}>
            <li className={classes.padding_5}>
              <Typography>
                <strong>Information We Collect</strong>
              </Typography>
              <Typography className={classes.padding_5}>
                We gather the following types of information:
              </Typography>
              <Box m={2} className={classes.boxStyleInner}>
                <ol className={classes.textIndent}>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Personal Information:</strong> This includes data
                      you provide directly, such as your name, email address,
                      phone number, billing details, and any other information
                      when registering for an account, subscribing to our
                      newsletter, or purchasing services.
                    </Typography>
                  </li>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Usage Data:</strong> We collect details on how you
                      interact with our website and services, including your IP
                      address, browser type, operating system, pages visited,
                      and the time and date of your visit.
                    </Typography>
                  </li>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Cookies and Tracking Technologies:</strong> We use
                      cookies and similar tracking tools to monitor activity on
                      our website and store specific information. You can adjust
                      cookie settings via your browser preferences.
                    </Typography>
                  </li>
                </ol>
              </Box>
            </li>
            <li className={classes.padding_5}>
              <Typography>
                <strong>How We Use Your Information</strong>
              </Typography>
              <Typography className={classes.padding_5}>
                Rezoomex uses the information collected for several purposes,
                such as:
              </Typography>
              <Box m={2} className={classes.boxStyleInner}>
                <ul className={classes.ul}>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Providing Services:</strong> To offer the products
                      and services you request, including processing
                      transactions and managing your account.
                    </Typography>
                  </li>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Communication:</strong> To send you newsletters,
                      marketing materials, and other content that may be of
                      interest to you.
                    </Typography>
                  </li>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Improvement of Services:</strong> To analyze user
                      trends and preferences to enhance our website’s
                      functionality and improve the user experience.
                    </Typography>
                  </li>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Security:</strong> To ensure the security of our
                      website and services, and to detect, prevent, and address
                      fraudulent activities or other illegal behavior.
                    </Typography>
                  </li>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Legal Compliance:</strong> To meet legal
                      obligations and enforce agreements.
                    </Typography>
                  </li>
                </ul>
              </Box>
            </li>
            <li className={classes.padding_5}>
              <Typography>
                <strong>Sharing of Information</strong>
              </Typography>
              <Typography className={classes.padding_5}>
                We do not sell, trade, or otherwise disclose your personal
                information to third parties, except in the following scenarios:
              </Typography>
              <Box m={2} className={classes.boxStyleInner}>
                <ul className={classes.ul}>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Service Providers:</strong> We may share your
                      information with trusted third-party service providers who
                      assist us in operating our website, conducting business,
                      or providing services to you, provided they agree to
                      maintain confidentiality.
                    </Typography>
                  </li>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Legal Requirements:</strong> We may disclose your
                      information when required by law or in response to valid
                      government requests.
                    </Typography>
                  </li>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Business Transfers:</strong> If Rezoomex undergoes
                      a merger, acquisition, or asset sale, your personal
                      information may be transferred. We will notify you before
                      your data is transferred and becomes subject to a new
                      privacy policy.
                    </Typography>
                  </li>
                </ul>
              </Box>
            </li>
            <li className={classes.padding_5}>
              <Typography>
                <strong>Data Security</strong>
              </Typography>
              <Typography className={classes.padding_5}>
                We take appropriate measures to protect your personal
                information. However, no internet transmission or electronic
                storage method is 100% secure, so we cannot guarantee its
                absolute safety.
              </Typography>
            </li>
            <li className={classes.padding_5}>
              <Typography>
                <strong>Your Rights</strong>
              </Typography>
              <Typography className={classes.padding_5}>
                You have the following rights concerning your personal
                information:
              </Typography>
              <Box m={2} className={classes.boxStyleInner}>
                <ul className={classes.ul}>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Access:</strong> You may request access to the
                      personal data we hold about you.
                    </Typography>
                  </li>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Correction:</strong> You can request corrections
                      to any inaccurate information.
                    </Typography>
                  </li>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Deletion:</strong> You can ask us to delete your
                      personal information, subject to any legal obligations.
                    </Typography>
                  </li>
                  <li className={classes.padding_5}>
                    <Typography>
                      <strong>Opt-Out:</strong> You can opt out of marketing
                      communications by following the unsubscribe instructions
                      in the messages or contacting us directly.
                    </Typography>
                  </li>
                </ul>
              </Box>
            </li>
            <li className={classes.padding_5}>
              <Typography>
                <strong>Children's Privacy</strong>
              </Typography>
              <Typography className={classes.padding_5}>
                Rezoomex does not knowingly collect personal information from
                children under 13. If we become aware that we have obtained such
                data, we will promptly delete it.
              </Typography>
            </li>
            <li className={classes.padding_5}>
              <Typography>
                <strong>Changes to This Privacy Policy</strong>
              </Typography>
              <Typography className={classes.padding_5}>
                We may revise this Privacy Policy occasionally. Any updates will
                be effective once posted on our website. We encourage you to
                periodically review this policy to stay informed about how we
                protect your data.
              </Typography>
            </li>
            <li className={classes.padding_5}>
              <Typography>
                <strong>Contact Us</strong>
              </Typography>
              <Typography>
                Rezoomex is a product of Alphonso Informex Inc. For any questions about this Privacy Policy or your data, please contact us at:
              </Typography>
              <Typography>
                <strong>{config.companyName}</strong>
              </Typography>
              <Typography>{config.companyAddress}</Typography>
              <Typography>{config.companyEmail}</Typography>
              <Typography>{config.companyNumber}</Typography>
            </li>
          </ol>
        </Box>
      </div>
      <Footer />
    </>
  );
};

PrivacyPolicy.propType = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PrivacyPolicy);
