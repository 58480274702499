import React from 'react';
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardMedia, Divider, Typography } from '@material-ui/core';
import RemoteWorkersBg from '../../assets/images/RemoteWorkersBg.png';
import LinksComponent from './LinksComponent';
import RezoomexLogo from './RezoomexLogo';
import Footer from './Footer';
import MenuComponent from './MenuComponent';
import { Link } from 'react-router-dom';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    bgContentText: {
        position: "absolute",
        color: "white",
        top: "82%",
        left: "50%",
        transform: "translateX(-50%)",
        fontSize: "4.1vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "Bold",
        [theme.breakpoints.up('lg')]: {
            fontSize: "49px !important",
        },
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        textIndent: "50px",
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            textIndent: "25px",
            marginBottom: "5px",
        },
    },
    Links: {
        textDecoration: "none !important",
        color: "#3298CC"
    }
})

const RemoteWorkers = (props) => {

    const { classes } = props;

    return (
        <div >
            <div>
                <div>
                    <Grid container>
                        <Grid container sx={{ position: 'relative' }}>
                            <Grid item lg={12} sm={12} md={12} xs={12} >
                                <Card className={classes.root}>
                                    <div className={classes.pageHeader}>
                                        <MenuComponent />
                                    </div>
                                    <div className={classes.bgImageContainer}>
                                        <CardMedia className={classes.bgImg} component="img" image={RemoteWorkersBg} alt="Remote Workers" />
                                        <div className={classes.bgContentText}>
                                            Are your remote workers really engaged?
                                        </div>
                                        <div className={classes.logoRezoomex}>
                                            <RezoomexLogo />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className={classes.LinksComponentClass}>
                <Grid container >
                    <Grid container>
                        <Grid item lg={12} sm={12} md={12} xs={12}>
                            <LinksComponent />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.leftRightContainer} >
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className={classes.pageContent}>
                            <div className={classes.pageContent}>
                                <Typography className={classes.pageParaText}>Is significant part of your company’s tech work being done remotely? Does your company outsource some software development work to remote contractors? </Typography>
                                <Typography className={classes.pageParaText}>Most of the contractors are being approached by potential clients with multiple opportunities. Even if they turn down such opportunities, they get distracted. This problem might be impacting a few of your remote employees too.</Typography>
                                <Typography className={classes.pageParaText}>Rezoomex has built a <Link className={classes.Links} to="/roboticengagement">chat-bot</Link> and <Link className={classes.Links} to="/learningearning">smart contracts</Link> to keep your remote workers focused and engaged. We leverage social physics and state of the art technologies like AI and blockchain to devise incentive programs and smart contracts to enforce the desired behavior and get higher commitment. There is no additional work needed. Our platform is instrumented to work off your existing systems to continually measure and monitor outcome based agile metrics.</Typography>
                                <Typography className={classes.pageParaText}>Agile is all about continuous improvement. Continuous self-improvement is the basis of continuous improvement. The Rezoomex NFT marketplace incentivizes your contractors to continue improving themselves as our platform continuously encourages and provides tips to acquire new skills. We are to a software professional what a fitness application is to an athlete.</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    )
}

RemoteWorkers.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(RemoteWorkers);