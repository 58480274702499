import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardMedia, Divider, TextField, Typography } from '@material-ui/core';
import Workingremotely from '../../assets/images/Workingremotely.webp';
import LinksComponent from './LinksComponent';
import RezoomexLogo from './RezoomexLogo';
import Footer from './Footer';
import MenuComponent from './MenuComponent';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';
import apiGatewayEndpoint from "../../config/index";

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    pageTitle: {
        textAlign: "left !important",
        fontWeight: "bold",
        fontSize: "20px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px !important",
            fontWeight: 'Bold !important',
            textAlign: "center !important"
        },
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },
    gridItem: {
        display: "flex !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important",
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonCardContainer: {
        [theme.breakpoints.only('xs')]: {
            marginTop: "10px"
        },
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        textIndent: "50px",
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            textIndent: "25px",
            marginBottom: "5px",
        },
    },
    bgContentText: {
        position: "absolute",
        fontWeight: "bold",
        fontFamily: "Proxima Nova",
        fontSize: "2.9vw",
        top: "4%",
        left: "50%",
        width: "100%",
        transform: "translateX(-50%)",
        [theme.breakpoints.up('lg')]: {
          fontSize: "40px !important",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "2.5vw",
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: "2.3vw",
        },
      },
      userDetailsLink: {
        textDecoration: "none",
        color: "#3298CC",
      },
      formContainer: {
        width: "60%",
        margin: "auto"
    },
    formButton: {
        margin: "10px"
    },
    loadingCircle: {
        zIindex: 5011,
        position: "fixed",
        padding: "0px",
        margin: "0px",
        top: "40%",
        textAlign: "center",
        border: "none",
        cursor: "default",
        opacity: 0.7,
        backgroundColor: "transparent",
    },
    apiMessageContainer: {
        margin: "3rem",
        padding: "30px !important",
    },
    apiSuccess: {
        fontWeight: "bold",
        fontSize: "20px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px !important",
            fontWeight: 'Bold !important',
            // padding: "15px !important"
        },
    },
    formTitle: {
        width: "60%",
        margin: "auto",
        textAlign: "left",
        padding: "12px"
    },
    titleText: {
        fontSize: "2.5vw",
        fontWeight: "500",
        marginTop: "1%",
        [theme.breakpoints.up('lg')]: {
            fontSize: "35px !important",
        },
    }
})

const UserDetails = (props) => {

    const { classes } = props;

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [userRequest, setUserRequest] = useState(false);
    const [error, setError] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);

    const saveData = () => {
        if(validEmailRegex.test(email.trim())) { 
            setUserRequest(true);
            const post_data = {
                firstName: firstName.trim(),
                lastName: lastName.trim(),
                email: email.trim(),
                company: company.trim()
            }
            axios.post(apiGatewayEndpoint.apiGatewayEndpoint + "api/user/v1/website/", post_data, {
                headers: {
                    Authorization: null,
                    "Content-Type": "application/json"
                }
            })
            .then(data => {
                setUserRequest(false);
            if (data.status === 200) {
                setError(false);
            }
            })
            .catch(err => {
                setError(true);
                resetState();
            });
        } else {
            setIsEmailValid(false);
        }
    }

    const isSubmitDisabled = () => {
        return !(firstName.trim() && email.trim() && company.trim());
    }

    const resetState = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setCompany('');
        setIsEmailValid(true);
    }

    return (
        <div >
            <div>
                <div>
                    <Grid container>
                        <Grid container sx={{ position: 'relative' }}>
                            <Grid item lg={12} sm={12} md={12} xs={12} >
                                <Card className={classes.root}>
                                    <div className={classes.pageHeader}>
                                        <MenuComponent />
                                    </div>
                                    <div className={classes.bgImageContainer}>
                                        <CardMedia className={classes.bgImg} component="img" image={Workingremotely} alt="Working Remotely" />
                                        <div className={classes.bgContentText}>
                                            Monkey business in your remote teams? 
                                            <div className={classes.titleText}>
                                                You can prevent it, with our deterrent
                                            </div>
                                        </div>
                                        <div className={classes.logoRezoomex}>
                                            <RezoomexLogo />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className={classes.LinksComponentClass}>
                <Grid container >
                    <Grid container>
                        <Grid item lg={12} sm={12} md={12} xs={12}>
                            <LinksComponent />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.leftRightContainer} >
                {
                    userRequest ? <Loading className={classes.loadingCircle} size={70} /> : null
                }
                {
                    error === false && (<div className={classes.apiMessageContainer}>
                        <Typography variant='h5' className={classes.apiSuccess}>Thank you!</Typography>
                    <Typography variant='h5' className={classes.apiSuccess}>One of us will reach out to you. Wait for our email.</Typography>
                </div>)

                }
                {
                    error === true && (<div>
                    <Typography variant='h5' className={classes.apiSuccess}>We are facing some technical issue. Please try after sometime.</Typography>
                </div>)

                }
                {
                    error === null && (
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <div className={classes.pageContent}>
                                    <div className={classes.pageContent}>
                                    <Typography className={classes.formTitle}>Kindly share your information. One of us will reach out to you.</Typography>
                                        <Grid container className={classes.formContainer} spacing={3}>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <TextField fullWidth id="first-name" label="First Name" variant="outlined" autoComplete='off' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                                <TextField fullWidth id="last-name" label="Last Name" variant="outlined" autoComplete='off' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <TextField 
                                                    fullWidth 
                                                    id="email" 
                                                    label="Email" 
                                                    variant="outlined" 
                                                    autoComplete='off'
                                                    helperText={isEmailValid ? "" : "Email is not valid"} 
                                                    value={email} 
                                                    onChange={(e) => setEmail(e.target.value)} 
                                                />
                                            </Grid>
                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                <TextField fullWidth id="company-name" label="Company Name" variant="outlined" autoComplete='off' value={company} onChange={(e) => setCompany(e.target.value)} />
                                            </Grid>
                                        </Grid>
                                        <Button variant="contained" color='primary' className={classes.formButton} disabled={isSubmitDisabled()} onClick={() => saveData()} >Submit</Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    )
                }
            </div>
            <Footer />
        </div>
    )
}

UserDetails.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(UserDetails);