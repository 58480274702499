import { Divider } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  LinkContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100% !important",
    // maxWidth: '1200px',
    // height: "50px",
    backgroundColor: "#999",
    alignItems: "center !important"
  },
  Links: {
    textDecoration: "none !important",
    color: "#fff",
    textAlign: "center !important",
    fontSize: "1.4vw",
    padding: "5px ",
    cursor: "pointer",
    flexGrow: "1",
    alignItems: "center",
    [theme.breakpoints.up('lg')]: {
      fontSize: "20px !important"
    },
  },
  box: {
    width: "100% !important"
  },
  dividerBar: {
    backgroundColor: "#fff !important",
    height: "2.8vh",
    alignItems: "center !important",
    width: "2px",
    [theme.breakpoints.up('sm')]: {
      height: "15px !important"
    },
  },
  diamondShape: {
    background: '#fff',
    height: '10px',
    textAlign: 'center',
    transform:'rotate(45deg)',
    width:'10px',
    [theme.breakpoints.only('sm')]: {
      height: "8px",
      width: '8px'
    },
  }
});

const LinksComponent = (props) => {
  const { classes } = props;

  const topScroll = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div>
      <div className={classes.LinkContainer}>
      <Link to="/deterrent" className={classes.Links} onClick={topScroll}>
          Deterrent
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/smartContracts" className={classes.Links} onClick={topScroll}>
          Smart Contracts
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/roboticengagement" className={classes.Links} onClick={topScroll}>
          Agile Copilot
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/paywatch" className={classes.Links} onClick={topScroll}>
        Paywatch
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/applicant" className={classes.Links} onClick={topScroll}>
        ATS
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/assessment" className={classes.Links} onClick={topScroll}>
          Assessment
        </Link>
        <span className={classes.diamondShape}></span>
        <Link to="/gigwork" className={classes.Links} onClick={topScroll}>
          Gig Work
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/earnhandsomereturn" className={classes.Links} onClick={topScroll}>
          NFT & Caliber$
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/remoteFirst" className={classes.Links} onClick={topScroll}>
          Remote First
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/oath" className={classes.Links} onClick={topScroll}>
          Oath
        </Link>

        {/* <Link to="/paywatch" className={classes.Links} onClick={topScroll}>
          Paywatch
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/assessment" className={classes.Links} onClick={topScroll}>
          Assessment
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/applicant" className={classes.Links} onClick={topScroll}>
          Applicant Tracking
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/performance" className={classes.Links} onClick={topScroll}>
          Recruiter Management
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/hrsystems" className={classes.Links} onClick={topScroll}>
          Integrations
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/reports" className={classes.Links} onClick={topScroll}>
          Reports
        </Link>
        <Divider className={classes.dividerBar} orientation="vertical" />
        <Link to="/adminstration" className={classes.Links} onClick={topScroll}>
          Administration
        </Link> */}
      </div>
    </div>
  );
};

LinksComponent.propType = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(LinksComponent);
