import config from '../../config/index';
import React from 'react';
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Hidden } from '@mui/material';
import { Card, CardContent, Typography, Box } from '@material-ui/core';
import HandImage from '../../assets/images/hand.webp';
import newWebsiteTreasury from '../../assets/images/newWebsiteTreasury.webp';
import newWebsiteCoins from '../../assets/images/newWebsiteCoins.webp';
import newWebsite23 from '../../assets/images/new-website-23.webp'
import AgileCopilot from '../../assets/images/agilecopilot.webp'
import NFTMarketplaceImage from '../../assets/images/NFTMarketplace.webp';
import TeamsImage from '../../assets/images/teams.webp';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    pageTitleContainer: {
        marginBottom: "20px !important",
        textAlign: "center !important",
        paddingRight: "5px",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important"
        },
    },
    pageTitle: {
        textAlign: "left !important",
        fontWeight: "bold",
        fontSize: "20px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px !important",
            fontWeight: 'Bold !important',
            textAlign: "center !important"
        },
    },
    cardIcon: {
        height: "70px",
        // width: "100px",
        [theme.breakpoints.only('xs')]: {
            height: "50px !important",
            width: "auto !important",
        },
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },
    sectionTitle: {
        padding: "10px 10px 0px 10px",
        color: "black",
        textAlign: "left !important",
        fontWeight: "bold !important",
        fontSize: "18px",
        marginTop: "-5px",
        // width: "500px",
        marginLeft: "-10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "13px !important",
            marginLeft: "16px",
            marginTop: "-6px",
            paddingLeft: "0px"
            // width: "200px",
        },
    },
    cardTitle: {
        padding: "10px 10px 0px 10px",
        color: "black",
        textAlign: "left !important",
        fontWeight: "bold !important",
        fontSize: "18px",
        marginTop: "-5px",
        width: "500px",
        position: "absolute",
        marginLeft: "-10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "13px !important",
            marginLeft: "23px",
            position: "absolute",
            marginTop: "-6px",
            width: "200px",
        },
    },
    headerTitle: {
        textAlign: "left",
        textJustify: "inter-word",
        fontSize: "16px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "12px !important",
        },
    },
    buttonStyle: {
        display: "inline-block",
        color: "#fff !important",
        textShadow: "0 0 2px rgba(0,0,0,.3)",
        boxShadow:
            "inset 0 0 2px 0 rgba(255,255,255,.4),inset 0 0 3px 0 rgba(0,0,0,.4),inset 0 0 3px 5px rgba(0,0,0,.05)",
        borderRadius: "4px",
        padding: "8px 16px",
        position: "relative",
        border: "none !important",
        fontWeight: 'Bold !important',
        background: "#4A90E2 !important",
        width: "144px",
        height: "50px",
        fontSize: "13px",
        [theme.breakpoints.only('xs')]: {
            lineHeight: "25px !important",
            width: "120px !important",
            fontSize: "11px !important",
            fontWeight: 'Bold !important'
        },
        ':before': {
            content: '',
            display: "block",
            position: "absolute",
            left: "2px",
            right: "2px",
            height: "3px",
            top: "0",
            background: "rgba(255,255,255,.6)",
            boxShadow: "0 1px 2px 0 rgba(255,255,255,0.5)",
        },
        ':after': {
            content: '',
            display: "block",
            position: "absolute",
            left: "2px",
            right: "2px",
            height: "3px",
            bottom: "0",
            background: "rgba(0,0,0,.15)",
            boxShadow: "0 -1px 2px 0 rgba(0,0,0,.30)"
        }
    },
    gridItem: {
        display: "flex !important",
        justifyContent: "center !important",
        margin: "10px 0px !important",
        [theme.breakpoints.only('sm')]: {
            margin: "10px 0px !important"
        },
        [theme.breakpoints.only('xs')]: {
            width: "300px !important",
        },
    },
    buttonCardContent: {
        fontSize: "15px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "12px !important",
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    bgContentText: {
        position: "absolute",
        color: "white",
        top: "73%",
        left: "50%",
        transform: "translateX(-50%)",
        fontSize: "4.1vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "Bold",
        [theme.breakpoints.up('lg')]: {
            fontSize: "49px !important",
        },
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    buttonsContainer: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "94%",
        transform: "translateX(-50%)",
        fontSize: "1vw",
        width: "10%",
        height: "5%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: '#999',
        alignItems: "center",
        borderRadius: "8px",
        justifyContent: "center",
        [theme.breakpoints.up('lg')]: {
            fontSize: "12px !important"
        },
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonsStyle: {
        textDecoration: "none",
        color: "#fff",
        padding: "5%"
    },
    divider: {
        backgroundColor: "white !important"
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    mainGridItem: {
        display: 'flex',
    },
    gridTextContainer: {
        width: "100%",
        display: 'grid',
        justifyContent: "center"
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonCardContainer: {
        [theme.breakpoints.only('xs')]: {
            marginTop: "10px"
        },
    },
    ReadMoreLinks: {
        textDecoration: "none",
        color: "#3298CC",
    },
    boxImg: {
        width: "120px",
        padding: "5px",
        alignItems: "center !important"
    },
    boxContent: {
        width: "380px",
        padding: "5px",
        textAlign: "left",
        textJustify: "inter-word",
        display: 'flex',
        [theme.breakpoints.only('xs')]: {
            fontSize: "12px !important"
        }
    },
    cardContiner: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        marginTop: "40px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "15px",
        [theme.breakpoints.only('xs')]: {
            width: "300px",
            padding: "20px",
            // height: "110px !important",
            marginTop: "11px",
            marginBottom: "0px",
        }
    },
    cardContinerLast: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        marginTop: "40px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "15px",
        [theme.breakpoints.only('xs')]: {
            width: "300px",
            padding: "20px",
            // height: "200px !important",
            marginTop: "11px",
            marginBottom: "0px",
        }
    },
    boxContentText: {
        textAlign: "left",
        [theme.breakpoints.only('xs')]: {
            fontSize: "12px"
        },
    },
    cardContentRight: {
        [theme.breakpoints.up('sm')]: {
            // paddingBottom: '0px !important',
        },
    },
    cardContinerFirst: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        marginTop: "40px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "15px",
        // [theme.breakpoints.up('sm')]: {
        //     marginTop: "94px",
        // },
        [theme.breakpoints.only('xs')]: {
            width: "300px",
            padding: "20px",
            height: "110px !important",
            marginTop: "11px",
            marginBottom: "0px",
        }
    }
})

const openCreateNft = () => {
    window.open(`${config.marketplaceUrl}create-nft`, '_blank', 'noreferrer');
}

const openSearchTalent = () => {
    window.open(`https://app.rezoomex.com/`, '_blank', 'noreferrer');
}

const NFTMarketPlace = (props) => {

    const { classes } = props;

    return (
        <Layout 
            horeImage={NFTMarketplaceImage} 
            title="Welcome to Rezoomex NFT marketplace the community of ethical, autonomous, remote teams"
            pageTitle="Gig Work Marketplace for Tech Professionals | Rezoomex"
            pageDescription="Rezoomex is a tech gig work platform that values ethics and performance. Smart contracts link pay to task completion, ensuring fairness. Engage with our Co-Pilot for focused, productive work. Join a community where integrity meets innovation."
            pageKeywords="Rezoomex, Gig Work Platform, Side projects for techies, Remote Work, Tech Gig work, Smart Contracts, Rezoomex Agile CoPilot, Ethics in tech work, CVs as NFTs"
        >
            <div className={classes.leftRightContainer} >
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <div className={classes.mainGridItem}>
                                <Grid item className={classes.gridItem} lg={12} md={12} sm={12} xs={12}>
                                    <Card className={classes.card} >
                                        <CardContent>
                                            <Button className={classes.buttonStyle} onClick={() => openSearchTalent()}>Search Talent</Button>
                                            <div className={classes.buttonCardContainer}>
                                                <Typography variant="caption" className={classes.buttonCardContent}>Talented engineers following professional ethics.<br /><Link aria-label="Talented Engineers" className={classes.ReadMoreLinks}> Read More...</Link></Typography>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </div>
                            <div className={classes.gridTextContainer}>
                                <Grid item className={classes.gridItem} lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.pageTitleContainer}>
                                        <Typography variant="subtitle2" className={classes.pageTitle}>Governance platform for progressive companies embracing the remote-first model to run a tight-ship!</Typography>
                                    </div>
                                </Grid>
                            </div>
                            <div>
                                <div>
                                    <Grid container className={classes.gridItemContainer}>
                                        <Grid item container className={classes.gridItem} spacing={2} lg={12} md={12} sm={12} xs={12} alignContent='flex-start'>
                                            <Grid item xs={12} >
                                                <Typography variant='h6' className={classes.sectionTitle} textAlign="left" >Ethical professionals under oath</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <img src={HandImage} className={classes.cardIcon} alt="Heart Icon"></img>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Typography className={classes.boxContentText}>Professionals are under oath to behave ethically. Those violating the norm would lose all their Caliber$ tokens!
                                                    <Link to="/deterrent" className={classes.ReadMoreLinks}> Read More...</Link>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container className={classes.gridItem} spacing={2} lg={12} md={12} sm={12} xs={12} alignContent='flex-start'>
                                            <Grid item xs={12} >
                                                <Typography variant='h6' className={classes.sectionTitle} textAlign="left" >Smart Contracts</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <img src={newWebsite23} className={classes.cardIcon} alt="Smart Contract"></img>
                                            </Grid>
                                            <Grid item xs={9}>
                                            <Typography className={classes.boxContentText}>Companies can instantly engage professionals with no waiting period. Use smart contracts to tightly link pay to measurable outcomes. Outsource smaller well-defined tasks.
                                                        <Link to="/smartContracts" className={classes.ReadMoreLinks}> Read More...</Link>
                                                    </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item container className={classes.gridItem} spacing={2} lg={12} md={12} sm={12} xs={12} alignContent='flex-start'>
                                            <Grid item xs={12} >
                                                <Typography variant='h6' className={classes.sectionTitle} textAlign="left" >Agile Copilot</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <img src={AgileCopilot} className={classes.cardIcon} alt="Agile Copilot"></img>
                                            </Grid>
                                            <Grid item xs={9}>
                                            <Typography className={classes.boxContentText}>A bot to by the side of your remote employees to keep them focused and engaged. It encourages and helps them to improve continuously.
                                                        <Link to="/roboticengagement" className={classes.ReadMoreLinks}> Read More...</Link>
                                                    </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}  >
                        <div>
                            <div className={classes.mainGridItem}>
                                <Grid item className={classes.gridItem} lg={12} md={12} sm={12} xs={12}>
                                    <Card className={classes.card}>
                                        <CardContent className={classes.cardContentRight}>
                                            <Button onClick={() => openCreateNft()} className={classes.buttonStyle}>
                                                Create NFT
                                            </Button>
                                            <div className={classes.buttonCardContainer}>
                                                <Typography variant="caption" className={classes.buttonCardContent}>Your NFT is key to complete autonomy and remote work.<br /> <Link className={classes.ReadMoreLinks} to='/virtualoath' aria-label="Virtual Oath"> Read More...</Link></Typography>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </div>
                            <div className={classes.gridTextContainer}>
                                <Grid item className={classes.gridItem} lg={12} md={12} sm={12} xs={12}>
                                    <div className={classes.pageTitleContainer}>
                                        <Typography variant="subtitle2" className={classes.pageTitle}>Your resume is your most valuable digital asset. Unleash its value!</Typography>
                                    </div>
                                </Grid>
                            </div>
                            <div>
                                <Grid container className={classes.gridContainerRight}>
                                    <Grid item container className={classes.gridItem} spacing={2} lg={12} md={12} sm={12} xs={12} alignContent='flex-start'>
                                    <Grid item xs={12}>
                                                </Grid>
                                        <Grid item xs={3}>
                                            <img src={newWebsiteTreasury} className={classes.cardIcon} alt="Treasury Icon"></img>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Typography className={classes.boxContentText}>You built your resume with a lot of perseverance and hard work. This is an opportunity to unlock the value to earn a handsome return.<Link to="/earnhandsomereturn" className={classes.ReadMoreLinks}> Read More...</Link></Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item container className={classes.gridItem} spacing={2} lg={12} md={12} sm={12} xs={12} alignContent='flex-start'>
                                            <Grid item xs={12} >
                                            </Grid>

                                        <Grid item xs={3}>
                                        <img src={newWebsiteCoins} className={classes.cardIcon} alt="Coin Icon"></img>
                                        </Grid>
                                        <Grid item xs={9}>
                                        <Typography className={classes.boxContentText}>
                                                    <ul>
                                                        <li>Convert your caliber to Caliber$ tokens.</li>
                                                        <li>Earn by entering smart contracts for interesting side projects and tasks.</li>
                                                        <li>Earn more by staking your Caliber$ on worthy professionals.</li>
                                                        <li>Build your reputation based on Caliber$ staked on you.</li>
                                                        <li>Achieve autonomy by ensuring baseline income.</li>
                                                    </ul>
                                                </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item container className={classes.gridItem} spacing={2} lg={12} md={12} sm={12} xs={12} alignContent='flex-start'>
                                            <Grid item xs={12} >
                                                <Typography variant='h6' className={classes.sectionTitle} textAlign="left" >Work from home or anywhere.</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                            <img src={TeamsImage} className={classes.cardIcon} alt="Coin Icon"></img>
                                        </Grid>
                                        <Grid item xs={9}>
                                        <Typography className={classes.boxContentText}>
                                                    Join other high caliber professionals who have gained complete autonomy and trust.
                                                    <Link to="/remoteFirst" className={classes.ReadMoreLinks}> Read More...</Link>
                                                </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Layout>
    )
}

NFTMarketPlace.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(NFTMarketPlace);
