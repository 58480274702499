import React from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  withStyles
} from "@material-ui/core";
import LinksComponent from "./LinksComponent";
import reportsAndDashboards from "../../assets/images/Reports&Dashboards.png";
import pipeline from "../../assets/images/4.png";
import tableau from "../../assets/images/3.jpg";
import recruitmentFunnel from "../../assets/images/recruitment-funnel.jpg";
import Footer from "./Footer";
import RezoomexLogo from "./RezoomexLogo";
import MenuComponent from "./MenuComponent";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  imgContainer: {
    position: "relative"
  },
  titleText: {
    position: "absolute",
    fontWeight: "bold",
    fontFamily: "Proxima Nova",
    fontSize: "3.5vw",
    top: "1%",
    left: "50%",
    width: "100%",
    transform: "translateX(-50%)",
    [theme.breakpoints.up('lg')]: {
      fontSize: "40px !important"
    },
    [theme.breakpoints.only('xs')]: {
      top: "60px !important"
    },
  },
  administrationImg: {
    display: "block",
    width: "100%"
  },
  card: {
    borderRadius: "none !important",
    boxShadow: "none !important"
  },
  pieChart: {
    maxWidth: "100%",
    maxHeight: "100%"
  },
  subtitle: {
    fontWeight: "bold"
  },
  subtitle2: {
    margin: "3rem 4rem 2rem 4rem",
    [theme.breakpoints.only('xs')]: {
      fontSize: "15px !important",
      margin: 0,
      padding: "10px"
    },
  },
  buttonBox: {
    width: "70%",
    height: "3rem",
    border: "2px solid #000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonBoxContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem"
  },
  scene: {
    width: "200px",
    height: "100px",
    border: "1px solid #CCC",
    perspective: "900px"
  },
  cube: {
    width: "200px",
    height: "100px",
    position: "relative",
    transformStyle: "preserve-3d",
    transform: "translateZ(-100px)"
  },
  cubeFace: {
    position: "absolute",
    width: "200px",
    height: "100px",
    border: "2px solid black",
    lineHeight: "100px",
    fontSize: "1rem",
    color: "black",
    textAlign: "center"
  },
  cubeFaceFront: { transform: "rotateY(  0deg) translateZ(100px)" },
  cubeFaceRight: { transform: "rotateY( 90deg) translateZ(100px)" },
  cubeFaceBack: { transform: "rotateY(180deg) translateZ(100px)" },
  cubeFaceLeft: { transform: "rotateY(-90deg) translateZ(100px)" },
  cubeFaceTop: { transform: "rotateX( 90deg) translateZ(0px)" },
  cubeFaceBottom: { transform: "rotateX(-90deg) translateZ(0px)" },
  logoRezoomex: {
    position: "absolute",
    color: "white",
    top: "2%",
    left: "42%",
    width: "80%",
    transform: "translateX(-50%)",
    fontSize: "4.5vw",
    height: "auto",
    [theme.breakpoints.only('xs')]: {
      marginTop: "-2px !important"
    },
  },
  pageHeader: {
    height: "50px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "white",
    [theme.breakpoints.up('sm')]: {
      display: "none !important"
    },
  },
  mainGridItem: {
    display: 'flex',
  },
  gridTextContainer: {
    width: "100%",
    display: 'grid',
    justifyContent: "center"
  },
  LinksComponentClass: {
    [theme.breakpoints.only('xs')]: {
      display: "none !important"
    },
  },
  gridImageContainer: {
    [theme.breakpoints.only('xs')]: {
      marginTop: "20px !important"
    },
  }
});

const ReportsAndDashboards = (props) => {
  const { classes } = props;

  return (
    <div>
      <Grid container>
        <Grid
          item
          className={classes.imgContainer}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <div className={classes.pageHeader}>
            <MenuComponent />
          </div>
          <img
            src={reportsAndDashboards}
            className={classes.administrationImg}
            alt="Reports and Dashboards"
          />
          <Typography className={classes.titleText} variant="h4">
            Reports and dashboards
          </Typography>
          <div className={classes.logoRezoomex}>
            <RezoomexLogo />
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.LinksComponentClass}>
          <LinksComponent />
        </Grid>
      </Grid>
      <Typography
        variant="h5"
        component="div"
        gutterBottom
        className={classes.subtitle2}
      >
        Rezoomex has a data warehouse that is preprogrammed to answer any
        questions about recruitment and availability of skills
      </Typography>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Card className={classes.card}>
            <CardContent>
              <Typography
                className={classes.subtitle}
                gutterBottom
                variant="h5"
                component="div"
              >
                Dashboards
              </Typography>
              <Typography gutterBottom variant="body2">
                Real time visualizations based on frequently used reports
              </Typography>
              <Grid container className={classes.gridImageContainer}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Box>
                    <img
                      src={pipeline}
                      alt="pipline"
                      className={classes.pieChart}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <Box>
                    <Typography>Tableau with Snowflake 10+ years</Typography>
                    <img
                      src={tableau}
                      alt="tableau"
                      className={classes.pieChart}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box>
                    <img
                      src={recruitmentFunnel}
                      alt="Recruitment Funnel"
                      className={classes.pieChart}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Card className={classes.card}>
            <CardContent className={classes.buttonBoxContainer}>
              <Typography
                className={classes.subtitle}
                gutterBottom
                variant="h5"
                component="div"
              >
                Reports and trackers
              </Typography>
              {/* <ButtonCube label="All jobs tracker" classes={classes} /> */}
              <Box className={classes.buttonBox}>All jobs tracker</Box>
              <Box className={classes.buttonBox}>
                All candidates for a job tracker
              </Box>
              <Box className={classes.buttonBox}>
                Sourcing to on-boarding recruitment
              </Box>
              <Box className={classes.buttonBox}>
                Recruiter performance and rewards
              </Box>
              <Box className={classes.buttonBox}>
                Prioritized report of open positions
              </Box>
              <Box className={classes.buttonBox}>Inactive positions report</Box>
              <Box className={classes.buttonBox}>
                Requisition lifecycle report
              </Box>
              <Box className={classes.buttonBox}>Create ad-hoc report</Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(ReportsAndDashboards);
