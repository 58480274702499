import React from 'react';
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, Typography, Box, CardMedia, Divider } from '@material-ui/core';
import RecruiterPerformance1 from '../../assets/images/RecruiterPerformance1.jpg';
import LinksComponent from './LinksComponent';
import rank from '../../assets/images/rank.png';
import cash from '../../assets/images/cash.png';
import target from '../../assets/images/target.png';
import peopleTriangle from '../../assets/images/peopleTriangle.png'
import RezoomexLogo from './RezoomexLogo';
import Footer from './Footer';
import MenuComponent from './MenuComponent';
import { Link } from 'react-router-dom';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
    },
    pageTitleContainer: {
        width: "100%",
        textAlign: "center !important",
    },
    pageTitle: {
        textAlign: "center !important",
        fontWeight: "bold",
        fontSize: "20px",
        margin: "30px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px",
            margin: "15px"
        }
    },
    cardIcon: {
        height: "70px",
        width: "100px",
        [theme.breakpoints.only('xs')]: {
            width: "auto",
            marginLeft: "-260px",
            height: "50px"
        }
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px",
    },
    gridItem: {
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
    },
    countValue: {
        fontSize: "50px",
        textShadow: "3px 1px #999",
        [theme.breakpoints.only('xs')]: {
            fontSize: "25px",
            textShadow: "1.5px 0.5px #999",
        }
    },
    countContent: {
        [theme.breakpoints.only('xs')]: {
            fontSize: "10px",
        }
    },
    countContainer: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.only('xs')]: {
            marginTop: "20px !important"
        },
    },
    countValueContainer: {
        width: "200px"
    },
    gridContainer: {
        width: "80%",
        justifyContent: "center"
    },
    cardContiner: {
        height: "160px !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "none !important",
        width: "600px",
        justifyContent: "center",
        [theme.breakpoints.only('xs')]: {
            flexDirection: "row-reverse !important",
            padding: "20px",
            height: "110px !important",
        }
    },
    cardContinerFirstLg: {
        height: "160px !important",
        boxShadow: "none !important",
        width: "600px",
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    cardContinerFirstXs: {
        height: "160px !important",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "none !important",
        width: "600px",
        justifyContent: "center",
        [theme.breakpoints.only('xs')]: {
            flexDirection: "row-reverse !important",
            padding: "20px",
            height: "110px !important",
            display: 'flex'
        },
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    },
    boxImg: {
        width: "100px",
        padding: "5px",
        alignItems: "center !important"
    },
    boxContent: {
        width: "400px",
        padding: "5px",
        textAlign: "left",
        textJustify: "inter-word",
        display: 'flex',
        [theme.breakpoints.only('xs')]: {
            fontSize: "12px"
        }
    },
    iconImg: {
        height: "70px",
        width: "100px",
        [theme.breakpoints.only('xs')]: {
            width: "auto",
            height: '50px'
        }
    },
    gridItemCard: {
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important"
        },
    },
    titleBottomAligned: {
        marginTop: '48px',
        [theme.breakpoints.only('xs')]: {
            fontSize: "12px",
            position: "absolute",
            marginTop: "-30px",
            marginLeft: "-46px",
        }
    },
    titleTopAligned: {
        marginTop: "-30px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "12px",
            position: "absolute",
            marginLeft: "-14px",
            marginTop: "20px",
        }
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    bgContentText: {
        position: "absolute",
        color: "black",
        top: "10%",
        left: "83%",
        transform: "translateX(-50%)",
        fontSize: "3.5vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "Bold",
        [theme.breakpoints.up('lg')]: {
            fontSize: "40px !important"
        },
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    root: {
        borderRadius: "0 !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    boxContentText: {
        [theme.breakpoints.only('xs')]: {
            fontSize: "12px"
        },
    }
})

const RecruiterPerformance = (props) => {

    const { classes } = props;

    return (
        <div >
            <div>
                <div>
                    <Grid container>
                        <Grid container>
                            <Grid item lg={12} sm={12} md={12} xs={12} >
                                <Card className={classes.root}>
                                    <div className={classes.pageHeader}>
                                        <MenuComponent />
                                    </div>
                                    <div className={classes.bgImageContainer}>
                                        <CardMedia className={classes.bgImg} component="img" image={RecruiterPerformance1} alt="Recruiter Performance" />
                                        <div className={classes.bgContentText}>
                                            Recruiter <br /> performance <br /> management
                                        </div>
                                        <div className={classes.logoRezoomex}>
                                            <RezoomexLogo />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div>
                <Grid container >
                    <Grid container className={classes.LinkContainer}>
                        <Grid item lg={12} sm={12} md={12} xs={12} className={classes.LinksComponentClass}>
                            <LinksComponent />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div>
                <div className={classes.pageTitleContainer}>
                    <Typography className={classes.pageTitle}>Rezoomex recruiter performance monitoring and enhancement</Typography>
                </div>
            </div>
            <div>
                <div className={classes.pageTitleContainer}>
                    <Typography className={classes.pageTitle}>Monthly performance of an average recruiter empowered by Rezoomex</Typography>
                </div>
            </div>
            <div className={classes.countContainer}>
                <Grid container className={classes.gridContainer} spacing={3}>
                    <Grid item lg={3} md={3} sm={6} xs={6} className={classes.gridItem}>
                        <div className={classes.countValueContainer}>
                            <div className={classes.countValue}>
                                100+
                            </div>
                            <div className={classes.countContent}>
                                candidates sourced
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={6} className={classes.gridItem}>
                        <div className={classes.countValueContainer}>
                            <div className={classes.countValue}>
                                50+
                            </div>
                            <div className={classes.countContent}>
                                interviews scheduled
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={6} className={classes.gridItem}>
                        <div className={classes.countValueContainer}>
                            <div className={classes.countValue}>
                                5+
                            </div>
                            <div className={classes.countContent}>
                                offers rolled out
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={6} className={classes.gridItem}>
                        <div className={classes.countValueContainer}>
                            <div className={classes.countValue}>
                                3+
                            </div>
                            <div className={classes.countContent}>
                                candidates onboarded
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div>
                <Grid container >
                    <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItemCard}>

                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItemCard}>
                        <Card className={classes.cardContinerFirstLg}>
                            <Typography className={classes.titleBottomAligned}>Stars do twice as well</Typography>
                            <img src={rank} className={classes.cardIcon} alt="Rank Icon"></img>
                            <Typography className={classes.titleTopAligned}>Superstars do thrice as well</Typography>
                        </Card>
                        <Card className={classes.cardContinerFirstXs}>
                            <Box className={classes.boxContent}>
                                <Typography className={classes.boxContentText}>Stars do twice as well <br />Superstars do thrice as well</Typography>
                            </Box>
                            <Box className={classes.boxImg}>
                                <img src={rank} className={classes.iconImg} alt="Target Icon"></img>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItemCard}>
                        <Card className={classes.cardContiner}>
                            <Box className={classes.boxContent}>
                                <Typography className={classes.boxContentText}>You can direct and ensure the recruiter to focus on the right priority and stay super-engaged by tweaking the rules that determine the rewards and incentives</Typography>
                            </Box>
                            <Box className={classes.boxImg}>
                                <img src={target} className={classes.iconImg} alt="Target Icon"></img>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItemCard}>

                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItemCard}>

                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItemCard}>
                        <Card className={classes.cardContiner}>
                            <Box className={classes.boxContent}>
                                <Typography className={classes.boxContentText}>Continuous feedback allows the recruiter to check her own performance and accomplishment at any time</Typography>
                            </Box>
                            <Box className={classes.boxImg}>
                                <img src={peopleTriangle} className={classes.iconImg} alt="Target Icon"></img>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItemCard}>
                        <Card className={classes.cardContiner}>
                            <Box className={classes.boxContent}>
                                <Typography className={classes.boxContentText}>Recruiter can redeem her reward points at any time</Typography>
                            </Box>
                            <Box className={classes.boxImg}>
                                <img src={cash} className={classes.iconImg} alt="Cash Icon"></img>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    )
}

RecruiterPerformance.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(RecruiterPerformance);