import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import EthicalMoonlighting from '../../assets/pdf/EthicalMoonlighting.pdf'


const styles = theme => ({
    pdfFile: {
        width: "100%", 
        height: "795px"
    }
})

const SurveryReport = (props) => {

    const { classes } = props

    let url = `${EthicalMoonlighting}`

    return (
        <>
            <div>
                <object data={url} type="application/pdf" className={classes.pdfFile} aria-label='Survery Report Pdf'/>
            </div>
        </>
    )
}

SurveryReport.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};


export default withStyles(styles, { withTheme: true })(SurveryReport);