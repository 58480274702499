import React from 'react';
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardMedia, Divider, Typography } from '@material-ui/core';
import MoonLighting from '../../assets/images/MoonLighting.png';
import LinksComponent from './LinksComponent';
import RezoomexLogo from './RezoomexLogo';
import Footer from './Footer';
import MenuComponent from './MenuComponent';
import { Link } from 'react-router-dom';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    pageTitle: {
        textAlign: "left !important",
        fontWeight: "bold",
        fontSize: "20px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px !important",
            fontWeight: 'Bold !important',
            textAlign: "center !important"
        },
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },
    gridItem: {
        display: "flex !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important",
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    bgContentText: {
        position: "absolute",
        color: "#000000",
        top: "25%",
        left: "32%",
        transform: "translateX(-50%)",
        fontSize: "2.9vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "Bold",
        [theme.breakpoints.up('lg')]: {
            fontSize: "40px !important",
        },
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonCardContainer: {
        [theme.breakpoints.only('xs')]: {
            marginTop: "10px"
        },
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        textIndent: "25px",
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            textIndent: "15px",
            marginBottom: "5px",
        },
    },
    titleText: {
        fontSize: "2.5vw",
        fontWeight: "500",
        marginTop: "1%",
        [theme.breakpoints.up('lg')]: {
            fontSize: "35px !important",
        },
    },
    titleText1: {
        position: "absolute",
        color: "#000000",
        top: "67%",
        left: "17%",
        transform: "translateX(-50%)",
        fontSize: "2.4vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "light",
        [theme.breakpoints.up('lg')]: {
            fontSize: "30px !important",
        },
    },
    titleText2: {
        position: "absolute",
        color: "#000000",
        top: "67%",
        left: "42%",
        transform: "translateX(-50%)",
        fontSize: "2.4vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "light",
        [theme.breakpoints.up('lg')]: {
            fontSize: "30px !important",
        },
    },
    dividerBar: {
        position: "absolute",
        backgroundColor: "#000000 !important",
        top: "67.5%",
        left: "30%",
        height: "2.9vh",
        width: "2px",
        [theme.breakpoints.down('xl')]: {
            height: "25px !important",
        },
        [theme.breakpoints.up('lg')]: {
            top: "68%",
        },
        [theme.breakpoints.down('sm')]: {
            height: "17px !important",
            width: "2px",
        },
        [theme.breakpoints.down('xs')]: {
            height: "8px !important",
            width: "0.5px",
        },
    },
    Links: {
        textDecoration: "none !important",
        color: "#3298CC"
    }
})

const Moonlighting = (props) => {

    const { classes } = props;

    return (
        <div >
            <div>
                <div>
                    <Grid container>
                        <Grid container sx={{ position: 'relative' }}>
                            <Grid item lg={12} sm={12} md={12} xs={12} >
                                <Card className={classes.root}>
                                    <div className={classes.pageHeader}>
                                        <MenuComponent />
                                    </div>
                                    <div className={classes.bgImageContainer}>
                                        <CardMedia className={classes.bgImg} component="img" image={MoonLighting} alt="Moonlighting" />
                                        <div className={classes.bgContentText}>
                                            Moonlighting and Remote Work Debate!
                                            <div className={classes.titleText}>
                                                Can we have a code of conduct?
                                            </div>
                                        </div>
                                        <div className={classes.titleText1}>
                                            3 PM, Friday May 26  
                                        </div>
                                        <Divider className={classes.dividerBar} orientation="vertical" />
                                        <div className={classes.titleText2}>
                                            J.W. Marriott, Pune
                                        </div>
                                        <div className={classes.logoRezoomex}>
                                            <RezoomexLogo />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className={classes.LinksComponentClass}>
                <Grid container >
                    <Grid container>
                        <Grid item lg={12} sm={12} md={12} xs={12}>
                            <LinksComponent />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.leftRightContainer} >
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className={classes.pageContent}>
                            <div className={classes.pageContent}>
                                <Typography className={classes.pageParaText}> We conducted many surveys on LinkedIn. These surveys clearly indicate that remote work is here to stay. But there is no clear standard for measuring remote work. Cases of disengagement ranging from muted video calls to employees absconding or disappearing are frequent.</Typography>
                                <Typography className={classes.pageParaText}>Coupled with remote work is the more sensitive topic of moonlighting, There’s no clear consensus among employees and companies on what is ethical. We are in conversation with some companies. They feel that some activities done outside the company are worthy of encouragement while others need to be discouraged or prohibited. We are trying to prepare a whitelist of activities that can be undertaken with transparency and impunity.</Typography>
                                <Typography className={classes.pageParaText}>We would like to have a wider debate to arrive at a code of conduct. This would be an in person meeting in JW Marriott, Pune on 26th May. The proceedings of the debate will be recorded and will be made available to those who can’t participate in person. This event is restricted to CXOs and can be attended by invitation only. If you are a CEO, CTO, CIO or CHRO please complete <a className={classes.Links} href="https://www.surveymonkey.com/r/NKBK65D" target="_blank" rel="noreferrer">this survey</a> and get invited to this hot debate.</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    )
}

Moonlighting.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Moonlighting);