import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Roboticengagement from '../../assets/images/Roboticengagement.webp';
import Layout from '../../components/Layout';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    pageTitle: {
        textAlign: "left !important",
        fontWeight: "bold",
        fontSize: "20px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px !important",
            fontWeight: 'Bold !important',
            textAlign: "center !important"
        },
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },
    gridItem: {
        display: "flex !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important",
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    bgContentText: {
        position: "absolute",
        color: "white",
        top: "82%",
        left: "50%",
        transform: "translateX(-50%)",
        fontSize: "4.1vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "Bold",
        [theme.breakpoints.up('lg')]: {
            fontSize: "49px !important",
        },
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    buttonsContainer: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "94%",
        transform: "translateX(-50%)",
        fontSize: "1vw",
        width: "10%",
        height: "5%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: '#999',
        alignItems: "center",
        borderRadius: "8px",
        justifyContent: "center",
        [theme.breakpoints.up('lg')]: {
            fontSize: "12px !important"
        },
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonsStyle: {
        textDecoration: "none",
        color: "#fff",
        padding: "5%"
    },
    divider: {
        backgroundColor: "white !important"
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonCardContainer: {
        [theme.breakpoints.only('xs')]: {
            marginTop: "10px"
        },
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        textIndent: "25px",
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize:"0.75rem",
            textIndent: "15px",
            marginBottom: "5px",
        },
    }
})

const RoboticEngagement = (props) => {

    const { classes } = props;
    return (
        <Layout 
            horeImage={Roboticengagement} 
            title="Agile Copilot"
            pageTitle="Rezoomex's Agile Copilot"
            pageDescription="Revolutionize remote work productivity with Rezoomex's Agile Copilot! Seamlessly track tasks, receive timely reminders and keep your team engaged and focused."
            pageKeywords="Rezoomex, Rezoomex Gig Work Platform, Remote work and productivity, Bot to increase productivity, Rezoomex's agile copilot, Remote work, Employers and remote work"
        >
            <Typography className={classes.pageParaText}>The remote-first model places a higher level of responsibility on remote workers. Staying focused and engaged is a prerequisite for achieving the desired levels of flexibility and autonomy.</Typography>
            <Typography className={classes.pageParaText}>The Agile copilot is the best way to engage, second only to face-to-face interactions. Athletes use wearable performance trackers to continuously monitor and improve their performance. Similarly, the Agile copilot serves as a self-help tool for remote workers to consistently perform better. It keeps track of their emails, instant messages, video calls, code check-ins, upcoming events, committed dates, time spent, and more. Gentle reminders, due concerns, and relevant questions from the copilot will guide the remote worker in the right direction. The copilot doesn’t report anything to superiors without the professional’s explicit approval. It makes every effort to ensure the professional's integrity. In extreme cases, superiors who have doubts about a professional’s engagement can ask the professional to share his or her logs.</Typography>
            <Typography className={classes.pageParaText}>The Agile copilot helps professionals track their Caliber$ by providing smart tips to increase it. Simultaneously, it keeps the professional’s resume updated and makes it available on request.</Typography>
            <Typography className={classes.pageParaText}>The Agile copilot will serve as the collective conscience of its team. For instance, everyone in the team will be informed about the team’s progress to ensure synchronization.</Typography>
            <Typography className={classes.pageParaText}>The Agile copilot will track measurable outcomes and calculate rewards. Companies can deliver rewards using smart contracts with gamification and leaderboards to increase engagement in a more enjoyable way.</Typography>
        </Layout>
    )
}

RoboticEngagement.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(RoboticEngagement);