import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import { Typography } from '@material-ui/core';
import RezoomexLogoIcon from '../../assets/images/rezoomex-logo.png';
import { Link } from 'react-router-dom';

const styles = theme => ({
    root: {
        boxShadow: "none !important",
        height: "80px",
        maxWidth: "150px",
        padding: "1% 3%",
        backgoundColor: 'transparent',
        display: "flex",
    },
    logoImg: {
        maxWidth: "100px",
        maxHeight: "60px",
        [theme.breakpoints.only('xs')]: {
            // width: "70px !important",
            width: '60px',
            height: '27px'
        },
    },
    tagLine: {
        fontSize: "0.71rem",
        color: "#000",
        width: "150px",
        textAlign: "left",
        [theme.breakpoints.only('xs')]: {
            fontSize: "7px !important",
            marginLeft: '3px'
        },
    },
    tagLineSecond: {
        fontSize: "0.71rem",
        color: "#000",
        width: "150px",
        textAlign: "left",
        marginLeft: '18px',
        marginTop: '-3px',
        [theme.breakpoints.only('xs')]: {
            fontSize: "7px !important",
            marginLeft: '17px',
        }
    },
    rezLogo: {
        marginLeft: "18px !important",
        [theme.breakpoints.only('xs')]: {
            fontSize: "13px !important"
        }
    }
})

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: 'auto'
});

const RezoomexLogo = (props) => {

    const { classes } = props;

    return (
        <div>
            <div>
                <div className={classes.logoImg}>   
                    <Link to="/" aria-label="Home Page"><Img className={classes.rezLogo} src={RezoomexLogoIcon} alt="Rezoomex Logo"></Img></Link>
                </div>
                <div>
                    <Typography className={classes.tagLine}>
                        Empowering Remote Teams
                    </Typography>
                    <Typography className={classes.tagLineSecond}>
                        Championing Ethics 
                    </Typography>
                </div>
            </div>
        </div>
    )
}

RezoomexLogo.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(RezoomexLogo);