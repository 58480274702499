import React from "react";
import { withStyles } from "@material-ui/core";
import LinksComponent from "./LinksComponent";
import { Box, Card, CardContent, Divider, Grid, Typography } from "@material-ui/core";
import  integration from "../../assets/images/integration.png";
import newWebsite16 from "../../assets/images/new-website-16.png";
import newWebsite17 from "../../assets/images/new-website-17.png";
import newWebsite18 from "../../assets/images/new-website-18.png";
import newWebsite19 from "../../assets/images/new-website-19.png";
import newWebsite20 from "../../assets/images/new-website-20.png";
import newWebsite21 from "../../assets/images/new-website-21.png";
import Cube from "../../assets/images/Cube1.png";
import Footer from "./Footer";
import RezoomexLogo from "./RezoomexLogo";
import MenuComponent from "./MenuComponent";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  imgContainer: {
    position: "relative"
  },
  titleText: {
    position: "absolute",
    fontWeight: "bold",
    fontFamily: "Proxima Nova",
    fontSize: "3.5vw",
    top: "5%",
    left: "50%",
    width: "100%",
    transform: "translateX(-50%)",
    [theme.breakpoints.up('lg')]: {
      fontSize: "49px !important"
    },
    [theme.breakpoints.only('xs')]: {
      top: '60px'
    },
  },
  administrationImg: {
    display: "block",
    width: "100%"
  },
  card: {
    borderRadius: "none !important",
    boxShadow: "none !important",
    [theme.breakpoints.only('xs')]: {
      display: "flex",
      flexDirection: "row-reverse",
      padding: "20px",
      width: "300px",
      justifyContent: "center"
    }
  },
  cardFirst: {
    borderRadius: "none !important",
    boxShadow: "none !important",
    [theme.breakpoints.only('xs')]: {
      display: "flex",
      flexDirection: "column-reverse !important",
      marginTop: "10px"
    }
  },
  img: {
    height: "100px",
    width: "auto",
    [theme.breakpoints.only('xs')]: {
      width: 'auto',
      height: '50px !important'
    }
  },
  imgBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "3rem 0"
  },
  buttonItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem"
  },
  scene: {
    width: "200px",
    height: "100px",
    // border: "1px solid #CCC",
    perspective: "900px",
  },
  cube: {
    width: "200px",
    height: "100px",
    position: "relative",
    transformStyle: "preserve-3d",
    transform: "translateZ(-100px)",
  },
  cubeFace: {
    position: "absolute",
    width: "200px",
    height: "100px",
    border: "2px solid black",
    lineHeight: "100px",
    fontSize: "1rem",
    color: "black",
    textAlign: "center",
  },
  cubeFaceFront: { transform: "rotateY(  0deg) translateZ(100px)" },
  cubeFaceRight: { transform: "rotateY( 90deg) translateZ(100px)" },
  cubeFaceBack: { transform: "rotateY(180deg) translateZ(100px)" },
  cubeFaceLeft: { transform: "rotateY(-90deg) translateZ(100px)" },
  cubeFaceTop: { transform: "rotateX( 90deg) translateZ(0px)" },
  cubeFaceBottom: { transform: "rotateX(-90deg) translateZ(0px)" },
  logoRezoomex: {
    position: "absolute",
    color: "white",
    top: "2%",
    left: "42%",
    width: "80%",
    transform: "translateX(-50%)",
    fontSize: "4.5vw",
    height: "auto",
    [theme.breakpoints.only('xs')]: {
      marginTop: "-2px !important"
    },
  },
  pageHeader: {
    height: "50px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "white",
    [theme.breakpoints.up('sm')]: {
      display: "none !important"
    },
  },
  mainGridItem: {
    display: 'flex',
  },
  gridTextContainer: {
    width: "100%",
    display: 'grid',
    justifyContent: "center"
  },
  LinksComponentClass: {
    [theme.breakpoints.only('xs')]: {
      display: "none !important"
    },
  },
  titleTextContainer: {
    [theme.breakpoints.only('xs')]: {
      top: "5% !important",
    },
  },
  boxContent: {
    [theme.breakpoints.only('xs')]: {
      fontSize: "12px !important",
      textAlign: "left"
    },
  },
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  pageTitleText: {
    [theme.breakpoints.only('xs')]: {
      fontSize: "15px"
    },
  },
  labelName: {
    position: "absolute",
    fontSize: "1.3rem",
    [theme.breakpoints.only('xs')]: {
      fontSize: "1rem"
    },
  },
  imgCube: {
    height:"100px",
    width:"200px"
  }
});

// const ButtonCube = (props) => {
//   const { classes, label } = props;
//   return (
//     <div className={classes.scene}>
//       <div className={classes.cube}>
//         <div className={`${classes.cubeFace} ${classes.cubeFaceFront}`}>
//           {label}
//         </div>
//         <div className={`${classes.cubeFace} ${classes.cubeFaceBack}`}>
//           {/* back */}
//         </div>
//         <div className={`${classes.cubeFace} ${classes.cubeFaceRight}`}>
//           {/* right */}
//         </div>
//         <div className={`${classes.cubeFace} ${classes.cubeFaceLeft}`}>
//           {/* left */}
//         </div>
//         <div className={`${classes.cubeFace} ${classes.cubeFaceTop}`}>
//           {/* top */}
//         </div>
//         <div className={`${classes.cubeFace} ${classes.cubeFaceBottom}`}>
//           {/* bottom */}
//         </div>
//       </div>
//     </div>
//   );
// };

const HRSystemsIntegration = (props) => {
  const { classes } = props;

  return (
    <div>
      <Grid container>
        <Grid
          item
          className={classes.imgContainer}
          lg={12}
          md={12}
          sm={12}
          xs={12}
        >
          <div className={classes.pageHeader}>
            <MenuComponent />
          </div>
          <img
            src={integration}
            className={classes.administrationImg}
            alt="HR Systems Integration"
          />
          <div className={classes.titleTextContainer}>
            <Typography className={classes.titleText} variant="h4">
              Integration with HR Systems
            </Typography>
          </div>
          <div className={classes.logoRezoomex}>
            <RezoomexLogo />
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.LinksComponentClass}>
          <LinksComponent />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
          <Card className={classes.cardFirst}>
            <Box>
              <CardContent>
                <Typography align="center" className={classes.pageTitleText}>
                  Rezoomex has published its own restful API end points and
                  built robust integrations.
                </Typography>
              </CardContent>
            </Box>
            <Box className={classes.imgBox}>
              <img src={newWebsite16} className={classes.img} alt="test" />
            </Box>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}>
          <Card className={classes.card}>
            <Box>
              <CardContent>
                <Typography align="center" className={classes.boxContent}>
                  Various HR management systems
                </Typography>
              </CardContent>
            </Box>
            <Box className={classes.imgBox}>
              <img src={newWebsite20} className={classes.img} alt="test" />
            </Box>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}>
          <Card className={classes.card}>
            <Box>
              <CardContent>
                <Typography align="center" className={classes.boxContent}>
                  Scheduling and time management
                </Typography>
              </CardContent>
            </Box>
            <Box className={classes.imgBox}>
              <img src={newWebsite21} className={classes.img} alt="test" />
            </Box>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}>
          <Card className={classes.card}>
            <Box>
              <CardContent>
                <Typography align="center" className={classes.boxContent}>
                  Email, messaging, and video conferencing
                </Typography>
              </CardContent>
            </Box>
            <Box className={classes.imgBox}>
              <img src={newWebsite17} className={classes.img} alt="test" />
            </Box>
          </Card>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}>
          <Card className={classes.card}>
            <Box>
              <CardContent>
                <Typography align="center" className={classes.boxContent}>
                  Issue tracking and version control
                </Typography>
              </CardContent>
            </Box>
            <Box className={classes.imgBox}>
              <img src={newWebsite19} className={classes.img} alt="test" />
            </Box>
          </Card>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
          <Card className={classes.card}>
            <Box>
              <CardContent>
                <Typography align="center" className={classes.boxContent}>
                  Resume sourcing portals
                </Typography>
              </CardContent>
            </Box>
            <Box className={classes.imgBox}>
              <img src={newWebsite18} className={classes.img} alt="test" />
            </Box>
          </Card>
        </Grid>
      </Grid>

      <Grid container className={classes.buttonContainer} >
        <Grid item lg={7} md={8} sm={12} xs={12} >
          <Grid container className={classes.buttonGridContainer}>
            <Grid
              item
              className={classes.buttonItem}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <img src={Cube} alt='Cube' className={classes.imgCube}/>
              <label className={classes.labelName}>DarwinBox</label>
            </Grid>
            <Grid
              item
              className={classes.buttonItem}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <img src={Cube} alt="Cube" className={classes.imgCube}></img>
              <label className={classes.labelName}>WorkDay</label>
            </Grid>
            <Grid
              item
              className={classes.buttonItem}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <img src={Cube} alt="Cube" className={classes.imgCube}></img>
              <label className={classes.labelName}>Peoplesoft</label>
            </Grid>
            <Grid
              item
              className={classes.buttonItem}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <img src={Cube} alt="Cube" className={classes.imgCube}></img>
              <label className={classes.labelName}>Jira</label>
            </Grid>
            <Grid
              item
              className={classes.buttonItem}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <img src={Cube} alt="Cube" className={classes.imgCube}></img>
              <label className={classes.labelName}>Github</label>
            </Grid>
            <Grid
              item
              className={classes.buttonItem}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <img src={Cube} alt="Cube" className={classes.imgCube}></img>
              <label className={classes.labelName}>API</label>
            </Grid>
            <Grid
              item
              className={classes.buttonItem}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <img src={Cube} alt="Cube" className={classes.imgCube}></img>
              <label className={classes.labelName}>Outlook</label>
            </Grid>
            <Grid
              item
              className={classes.buttonItem}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <img src={Cube} alt="Cube" className={classes.imgCube}></img>
              <label className={classes.labelName}>Google</label>
            </Grid>
            <Grid
              item
              className={classes.buttonItem}
              lg={4}
              md={4}
              sm={12}
              xs={12}
            >
              <img src={Cube} alt="Cube" className={classes.imgCube}></img>
              <label className={classes.labelName}>LinkedIn</label>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(HRSystemsIntegration);
