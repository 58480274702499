import React from 'react';
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PressReleaseImg from '../../assets/images/PressReleaseImg.webp';
import RezoomexLogoIcon from '../../assets/images/rezoomex-logo.png';
import Layout from '../../components/Layout';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        }
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        }
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        }
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        }
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            marginBottom: "5px",
        }
    },
    titleText: {
        textAlign: 'left',
        marginLeft: '10px',
        marginBottom: '10px'
    },
    listPointDetails: {
        color: "#000",
        paddingBottom: '10px',
    },
    contactInfoLogoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gridGap: 5
        }
    },
    contactInfo: {
        textAlign: 'left',
        marginLeft: '10px',
        fontWeight: '600'
    },
    rezoomexLogo: {
        height: '100px',
        width: '200px',
        [theme.breakpoints.only('xs')]: {
            height: '50px',
            width: '110px'
        }
    },
    rezoomexLogoContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
            marginLeft: '6px',
            marginBottom: '15px'
        }
    },
    titleTextContact: {
        textAlign: 'left',
        marginLeft: '10px',
        marginBottom: '10px',
        color: '#3298CC'
    }
})

const PressRelease = (props) => {

    const { classes } = props;

    return (
        <div >
            <Layout horeImage={PressReleaseImg}>
                <div className={classes.leftRightContainer} >
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <div className={classes.pageContent}>
                                <Typography className={classes.titleText}><strong>EVENT PRESS RELEASE</strong></Typography>
                                <Typography className={classes.titleTextContact}><strong>Contact Information</strong></Typography>
                                <Grid className={classes.contactInfoLogoContainer} item lg={12} md={12} sm={12} xs={12}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <Typography className={classes.contactInfo}>Vinayak Joglekar</Typography>
                                        <Typography className={classes.contactInfo}>+91-9860666618</Typography>
                                        <Typography className={classes.contactInfo}>vinayak@rezoomex.com</Typography>
                                        <Typography className={classes.contactInfo}>Rezoomex</Typography>
                                    </Grid>
                                    <Grid className={classes.rezoomexLogoContainer} item lg={6} md={6} sm={6} xs={6}>
                                        <img className={classes.rezoomexLogo} src={RezoomexLogoIcon} alt="rezoomex-logo"></img>
                                    </Grid>
                                </Grid>
                                <div className={classes.pageParaText}>
                                    <Typography className={classes.listPointDetails}>Pune, June 29 - Rezoomex, a leading organization in the IT industry, recently organized a thought-provoking debate on the topic of ethical moonlighting and its possibilities. The two-hour long event witnessed the participation of 55 eminent professionals, including CEOs and leaders, who shared their insights and perspectives on the matter.</Typography>
                                    <Typography className={classes.listPointDetails}>During the engaging debate, 75% of the attendees expressed their support for ethical moonlighting, highlighting its potential benefits. However, there were varying opinions on how moonlighting should be approached and regulated. The participants shed light on several aspects and considerations that revolve around engaging in moonlighting activities.</Typography>
                                    <Typography className={classes.listPointDetails}>Mr. Vinayak Joglekar, Founder Director of Rezoomex, emphasized his support for ethical moonlighting when carried out with the prior approval of the primary employer. He pointed out that professionals have been undertaking external projects even before the term "moonlighting" gained popularity. Mr. Joglekar highlighted how highly paid and highly qualified consultants have always shared their time with multiple clients, showcasing the possibility of ethical moonlighting.</Typography>
                                    <Typography className={classes.listPointDetails}>Akash Malve, Global Head of Data Science & AI at Exela Technologies, supported moonlighting when conducted within a defined framework based on Competition, Contracts, Competency, and Conflict. He stressed the importance of proper management and adherence to ethical guidelines to ensure a positive moonlighting experience.</Typography>
                                    <Typography className={classes.listPointDetails}>Jay Pathak, Angular Architect at Etrade Menlopark, shared his personal experience where hiring a candidate with rare skills proved challenging. He expressed his support for moonlighting as it enabled him to complete his project within the required timeframe. Mr. Pathak believes that moonlighting can be a valuable solution for accessing specialized skills that may not be readily available for full-time hiring.</Typography>
                                    <Typography className={classes.listPointDetails}>Aniruddha Paranjape, CEO of Kanaka Software, endorsed moonlighting due to its potential for individuals to acquire rare skills that may be unavailable through traditional hiring methods. As long as work is delivered punctually and customers are satisfied, Mr. Paranjape supports moonlighting within boundaries that do not conflict with the organization's interests.</Typography>
                                    <Typography className={classes.listPointDetails}>Johnson Abraham, Principal Software Engineering Manager at Cloudreach, highlighted the positive aspects of moonlighting, including the development of qualities such as autonomy, time management, confidence, and a sense of ownership. He emphasized how moonlighting can contribute to personal and professional growth, as well as upskilling.</Typography>
                                    <Typography className={classes.listPointDetails}>Harish Rohokale, Founder & CEO of Apptware & Appmart, expressed his concerns about moonlighting, citing client code confidentiality, productivity issues, and security as potential drawbacks. He implemented a model within his company that allows team members to earn and learn through in-house projects, providing an alternative to moonlighting.</Typography>
                                    <Typography className={classes.listPointDetails}>Ronak Patil, Founding Technical Engineer at Cast.AI, generally agreed with moonlighting within a defined framework. He highlighted the additional financial incentive it offers, especially for juniors with lower salaries who face challenges in expensive cities like Pune and Bangalore. Mr. Patil suggested that moonlighting can serve as a viable option for individuals seeking additional income.</Typography>
                                    <Typography className={classes.listPointDetails}>Manish Agrawal, Co-founder & CTO of Yagna IQ Inc, opposed moonlighting, stating that knowledge workers' responsibilities often extend beyond regular working hours, particularly in product companies. He raised concerns about the potential negative impact on family time, sleep, and concentration on the primary job. Mr. Agrawal recommended individuals to explore freelancing or consulting as alternatives.</Typography>
                                    <Typography className={classes.listPointDetails}>Shreya Thombre, Project Manager (PMO) at IBM, also voiced her opposition to moonlighting, placing a high value on loyalty to the primary employer. She emphasized the significant risks associated with conflicts of interest and data privacy that can arise from allowing moonlighting activities.</Typography>
                                    <Typography className={classes.listPointDetails}>Pratap Shrotriya, CEO of SplendorNet Technologies, presented perspectives from both employers and employees. From an employee's perspective, he highlighted the importance of satisfaction over monetary reasons. Mr. Shrotriya stated that engaging in multiple activities may prevent individuals from finding time for themselves and their families. As an employer, he expressed the company's preference for employees to focus on their primary work and conserve their energy rather than engaging in moonlighting activities.</Typography>
                                    <Typography className={classes.listPointDetails}>Kiran Ratnakar, Director of Cybersecurity, Cloud, Encora, shared his experience of moonlighting with prior approval from the CTO. He acknowledged the potential benefits of additional knowledge gained from moonlighting that can be useful to the current company. However, Mr. Ratnakar cautioned about the risks associated with intellectual property protection and insider threats, emphasizing the need for certain constraints on moonlighting activities.</Typography>
                                    <Typography className={classes.listPointDetails}>Salil Khedkar, Head of Software Engineering at Microlise, adopted a neutral standpoint. While supporting ethical moonlighting for building a talented and thriving team, he stressed the importance of addressing productivity and work-life balance issues. Mr. Khedkar recommended allowing moonlighting activities with proper guidelines and regulations.</Typography>
                                    <Typography className={classes.listPointDetails}>The debate also brought to light the reasons why individuals consider moonlighting, including underutilization of capabilities and idle time. Employees emphasized the need for companies to value their output and focus on outcomes rather than measuring time spent or filling time sheets. However, a discrepancy arises as companies often charge clients based on the time and material model, leading to a mismatch in expectations.</Typography>
                                    <Typography className={classes.listPointDetails}>A unique proposal was discussed during the event, suggesting the development of an NFT system to lock in the identity of employees engaging in moonlighting. Additionally, a three-way smart contract was proposed to ensure that both companies simultaneously working with the employees can receive the appropriate share of their time and attention.</Typography>
                                    <Typography className={classes.listPointDetails}>Overall, the debate on ethical moonlighting organized by Rezoomex received a positive response and facilitated a healthy exchange of ideas. Rezoomex extends its gratitude to all the participants for their valuable time and contributions. The organization expresses its intention to organize further events on this topic in the near future.</Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Layout>
        </div>
    )
}

PressRelease.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PressRelease);