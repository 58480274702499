import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CBDCImage from '../../assets/images/CBDC.webp';
import Layout from '../../components/Layout';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },

    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            marginBottom: "5px",
        },
    },
})

const Deterrent = (props) => {

    const { classes } = props;

    return (
        <Layout 
            horeImage={CBDCImage}
            pageTitle="Smart Contracts for Smarter Work | Rezoomex"
            pageDescription="Rezoomex's smart contracts provides transparency and security for Gig Workers and employers. Once entered, the terms, deadlines and payment details are all encoded into the smart contract."
            pageKeywords="Rezoomex Smart Contracts, Smart Contracts in Gig work, Rezo tokens, Gig work and upi payments, CBDC and Gig work Rezoomex, Rezoomex Gig Work Platform, Smart Contracts"
        >
            <div className={classes.pageContent}>
                <Typography className={classes.pageParaText}>The Unified Payments Interface(UPI) has made history. India has become the leader in democratizing payments by reaching out to more than a billion people. Even a vegetable vendor accepts payments using UPI. The total volume of transactions reached more than 14 lac crore rupees in the month of June 2023.</Typography>
                <Typography className={classes.pageParaText}>CBDC pilot is another success story. India has launched its digital currency on 16th November 2022. Later we launched the retail version in December 2022.– eRupee. Many individuals are now holding eRupee wallets provided by their banks.</Typography>
                <Typography className={classes.pageParaText}>Rezoomex is proud to be a part of the success story of CBDC. We have our own Rezo$ tokens which wrap eRupee held in a special account with the custodian bank.</Typography>
                <Typography className={classes.pageParaText}>Rezo$ is the currency of the tech talent tokenomy. Companies are engaging employees and contractors in smart contracts that trigger payments in Rezo$ tokens. Smart contracts are triggered by digital events – like completion of a task in an app or end of a month or a quarter or achieving a goal digitally captured by a metric. This automation obviates the need for the parties to know and trust each other. A contractor could do business with a completely unknown party and be assured of getting paid after achieving the pre-set goal. This opens up a whole new world of opportunities.</Typography>
                <Typography className={classes.pageParaText}>Rezoomex plans to empower tech professionals working from their homes even in remote locations provided they follow professional ethics. In our vision software engineering could become a cottage industry needing only a laptop, electricity and internet.</Typography>
                <Typography className={classes.pageParaText}>India has recently enacted the Digital Personal Data Protection Act. Rezoomex lauds and supports the respect for personal data. We want to restore the rightful ownership of resumes shared by jobseekers to them, and we have smart contracts that reward them for allowing access to personal data.</Typography>
            </div>
        </Layout>
    )
}

Deterrent.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Deterrent);