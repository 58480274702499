import React from 'react';
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import PieChart from '../../assets/images/PieChart.webp';
import Proctored from '../../assets/images/Proctored.webp';
import Mobile from '../../assets/images/Mobile.webp';
import questionPaper from '../../assets/images/questionPaper.webp';
import TechQuiz from '../../assets/images/TechQuiz.webp';
import Layout from '../../components/Layout';


const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
    },
    pageTitle: {
        fontWeight: "bold",
        fontSize: "20px",
        padding: "0 0 30px 0 !important",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px !important",
            fontWeight: 'Bold !important',
            padding: "15px !important"
        },
    },
    cardIcon: {
        height: "70px",
        minWidth: "100px",
        objectFit: "contain",
        [theme.breakpoints.only('xs')]: {
            height: "50px !important",
            width: "auto !important",
        },
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px"
        },
    },
    headerTitle: {
        textAlign: "left",
        textJustify: "inter-word",
        fontSize: "16px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "12px !important",
        },
    },
    buttonStyle: {
        display: "inline-block",
        color: "#fff !important",
        textShadow: "0 0 2px rgba(0,0,0,.3)",
        boxShadow:
            "inset 0 0 2px 0 rgba(255,255,255,.4),inset 0 0 3px 0 rgba(0,0,0,.4),inset 0 0 3px 5px rgba(0,0,0,.05)",
        borderRadius: "4px",
        padding: "8px 16px",
        position: "relative",
        border: "none !important",
        fontWeight: 'Bold !important',
        background: "#4A90E2 !important",
        width: "144px",
        height: "50px",
        fontSize: "13px",
        [theme.breakpoints.only('xs')]: {
            lineHeight: "25px !important",
            width: "120px !important",
            fontSize: "11px !important",
            fontWeight: 'Bold !important'
        },
        ':before': {
            content: '',
            display: "block",
            position: "absolute",
            left: "2px",
            right: "2px",
            height: "3px",
            top: "0",
            background: "rgba(255,255,255,.6)",
            boxShadow: "0 1px 2px 0 rgba(255,255,255,0.5)",
        },
        ':after': {
            content: '',
            display: "block",
            position: "absolute",
            left: "2px",
            right: "2px",
            height: "3px",
            bottom: "0",
            background: "rgba(0,0,0,.15)",
            boxShadow: "0 -1px 2px 0 rgba(0,0,0,.30)"
        }
    },
    gridItem: {
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important"
        },
    },
    buttonCardContent: {
        fontSize: "15px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "12px !important",
        },
    },
    bgImageContainer: {
        position: "relative",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    bgContentText: {
        position: "absolute",
        color: "white",
        top: "10%",
        left: "88%",
        transform: "translateX(-50%)",
        fontSize: "3.5vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "bold",
        [theme.breakpoints.up('lg')]: {
            fontSize: "40px !important"
        },
        [theme.breakpoints.down('md')]: {
            left: "86% !important",
        },
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    root: {
        borderRadius: "0 !important",
        border: "none !important",
        boxShadow: "none !important",
    },
    buttonsContainer: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "94%",
        transform: "translateX(-50%)",
        fontSize: "1vw",
        width: "10%",
        height: "5%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: '#999',
        alignItems: "center",
        borderRadius: "8px",
        justifyContent: "center",
        [theme.breakpoints.up('lg')]: {
            fontSize: "12px !important"
        },
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonsStyle: {
        textDecoration: "none",
        color: "#fff",
        padding: "5%"
    },
    divider: {
        backgroundColor: "white !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    mainGridItem: {
        display: 'flex',
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonCardContainer: {
        [theme.breakpoints.only('xs')]: {
            marginTop: "10px"
        },
    }
})


const Assesment = (props) => {

    const { classes } = props;

    return (
        <Layout 
            horeImage={TechQuiz} 
            title="Video Proctored Technical Quiz"
            pageTitle="Video Proctored Technical Quiz | Rezoomex"
            pageDescription="Experience the convenience of video-proctored quizzes for initial technical assessments. Rezoomex's mobile-friendly platform allows individuals to create personalized quizzes, offering in-depth reporting for accurate evaluations and insights."
            pageKeywords="Technical assessment, Tech hiring, Hiring IT professionals, Technical quiz tool, Video interviewing, Hiring tool, Video proctored technical quiz, Rezoomex, Rezoomex tech hiring tool"
        >
            <Typography variant='h5' className={classes.pageTitle}>Preliminary Technical Assessment</Typography>
            <div className={classes.leftRightContainer} >
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} >
                        <div>
                            <div className={classes.mainGridItem}>
                                <Grid item className={classes.gridItem} lg={12} md={12} sm={12} xs={12}>
                                    <Card className={classes.card} >
                                        <CardContent>
                                            <Button className={classes.buttonStyle}>Add Question</Button>
                                            <div className={classes.buttonCardContainer}>
                                                <Typography variant="caption" className={classes.buttonCardContent}>Earn Rezo$ every time your question is used.</Typography>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </div>
                            <div>
                                <div>
                                    <Grid container>
                                        <Grid item className={classes.gridItem} lg={12} md={12} sm={12} xs={12}>
                                            <Card className={classes.card}>
                                                <CardHeader
                                                    avatar={
                                                        <div className={classes.cardIconContainer}>
                                                            <img src={Proctored} className={classes.cardIcon} alt="Proctored Icon"></img>
                                                        </div>
                                                    }
                                                    title="Video proctored- with AI assistant to quickly identify suspicious frames."
                                                    classes={{
                                                        title: classes.headerTitle
                                                    }}
                                                />
                                            </Card>
                                        </Grid>

                                        <Grid className={classes.gridItem} item lg={12} md={12} sm={12} xs={12}>
                                            <Card className={classes.card}>
                                                <CardHeader
                                                    avatar={
                                                        <div className={classes.cardIconContainer}>
                                                            <img src={PieChart} className={classes.cardIcon} alt="Pie Chart Icon"></img>
                                                        </div>
                                                    }
                                                    title="Comprehensive reporting. Average time taken to answer, number of times the question was administered, and the number of times it was answered correctly."
                                                    classes={{
                                                        title: classes.headerTitle
                                                    }}
                                                />
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}  >
                        <div>
                            <div className={classes.mainGridItem}>
                                <Grid item className={classes.gridItem} lg={12} md={12} sm={12} xs={12}>
                                    <Card className={classes.card}>
                                        <CardContent>
                                            <Button className={classes.buttonStyle}>Create Test</Button>
                                            <div className={classes.buttonCardContainer}>
                                                <Typography variant="caption" className={classes.buttonCardContent}>Easy to create – almost automated</Typography>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </div>
                            <div>
                                <Grid container>
                                    <Grid className={classes.gridItem} item lg={12} md={12} sm={12} xs={12}>
                                        <Card className={classes.card}>
                                            <CardHeader
                                                avatar={
                                                    <div className={classes.cardIconContainer}>
                                                        <img src={questionPaper} className={classes.cardIcon} alt="Pie Chart Icon"></img>
                                                    </div>
                                                }
                                                title="Question bank with 30+ technologies, MCQs continuously updated by a team of experts, includes thousands of code snippets."
                                                classes={{
                                                    title: classes.headerTitle
                                                }}
                                            />
                                        </Card>
                                    </Grid>

                                    <Grid className={classes.gridItem} item lg={12} md={12} sm={12} xs={12}>
                                        <Card className={classes.card}>
                                            <CardHeader
                                                avatar={
                                                    <div className={classes.cardIconContainer}>
                                                        <img src={Mobile} className={classes.cardIcon} alt="Mobile Icon"></img>
                                                    </div>
                                                }
                                                title="Mobile based, the candidate can answer this quiz at anytime from anywhere."
                                                classes={{
                                                    title: classes.headerTitle
                                                }}
                                            />
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Layout>
    )
}

Assesment.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Assesment);