/**
 * Renders the SCDD page component.
 * 
 * Title: Smart Contract Driven Development!
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.classes - The CSS classes for styling the component.
 * @returns {JSX.Element} The SCDD page component.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import SCDDImage from '../../assets/images/SCDD.webp';
import Layout from '../../components/Layout';
import { Box, Button } from '@mui/material';
import { useMemo } from 'react';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },

    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            marginBottom: "5px",
        },
    },
})

const SCDD = (props) => {

    const { classes } = props;

    const openLink = () => {
        window.open("https://rezoomex.teachable.com/p/smart-contract-driven-development", '_blank')
    };
    return (
        <Layout 
            horeImage={SCDDImage} 
            title="Smart Contract Driven Development!"
            pageTitle="Smart Contract Driven Development Course | Rezoomex"
            pageDescription="Discover how to become 10x more productive tech professional with Rezoomex's Smart Contract Driven Development Free Course. Get ready for your first Gig Work."
            pageKeywords="Rezoomex, Rezoomex courses, Gig Work Platform, Rezoomex Smart Contract, Productive Tech Professional, GigWorkers, Smart Contract Driven Development Course, Remote Work, Rezoomex teachable"
        >
            <div className={classes.pageContent}>
                <Typography variant='h5'>Enterprise class software development by agile teams of gigworkers!</Typography>
                <Typography className={classes.pageParaText}>Gigworkers or freelancers were known for quick coding of small projects and prototypes. Now Rezoomex brings the best practices followed by enterprise class software developers and architects to the gigwork marketplace. This innovative way of doubling the productivity and quality at half the costs is facilitated by advances in blockchain, smart contracts and generative AI.</Typography>
                <Typography className={classes.pageParaText}>Gigworker’s smart contracts are directly linked to tests and metrics that measure the outcome. Like all enterprise software these contracts are executed by agile teams of gigworkers. These teams are engaged by companies or DAOs (Decentralized Autonomous Organizations) using smart contracts as described below.</Typography>
                <Typography className={classes.pageParaText}>The highest project level smart contract is between the project sponsor and the team and is based on metrics that measure business outcome. The risk associated with these contracts is assumed by the companies or the DAOs. The risk is mitigated by having 2 to 4 metrics in the smart contract so that we don’t end up sacrificing quality for productivity or economy for quality.  The product owners can be engaged by simple time and material smart contracts to protect them from the risk. Here are some typical project outcomes that can be measured by tools like Google Analytics or Mixpanel.
                    <Box m={2}>
                        <ul>
                            <li>User engagement (average session time)</li>
                            <li>Number of active users</li>
                            <li>Number of new users</li>
                            <li>Number of paid users</li>
                            <li>Number of returning / repeat / regular users.</li>
                        </ul>
                    </Box>
                </Typography>
                <Typography className={classes.pageParaText}>Second level of smart contracts are for the project managers which are typically related to a release goal. These tests are created by the product owner using Typical examples of release goal objectives are based the successful execution of these tests.
                    <Box m={2}>
                        <ul>
                            <li>Regression tests</li>
                            <li>Sanity tests</li>
                            <li>Security/performance or usability tests</li>
                            <li>Functional testing</li>
                        </ul>
                    </Box></Typography>
                <Typography className={classes.pageParaText}>The lowest level of smart contracts are for the developers. They are challenged by unit tests that are created by project managers using generative AI. Smart contracts are triggered by unit tests. This ensures high test coverage and quality code. Here are some tests that can be used for these low level smart contracts.
                    <Box m={2}>
                        <ul>
                            <li>Unit tests</li>
                            <li>Static Analysis</li>
                            <li>Integration tests</li>
                        </ul>
                    </Box>
                </Typography>

                <Button
                    variant="contained"
                    color='primary'
                    className={classes.formButton}
                    onClick={openLink}
                >
                    Register for the free course!
                </Button>

            </div>
        </Layout>
    )
}

SCDD.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SCDD);