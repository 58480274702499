import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import UserIcon from '../../assets/images/User.png';
import HamburgerIcon from '../../assets/images/Menu.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from "@mui/material";

const styles = (theme) => ({
    pageHeaderIconContainer: {
        display: "flex",
        justifyContent: "flex-end"
    },
    pageHeaderIcon: {
        padding: "10px",
        height: "36px",
        width: "36px"
    },
    Links: {
        textDecoration: "none !important",
        color: "black !important"
    },
    menuList: {
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
});

const MenuComponent = (props) => {
    const { classes } = props;


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const open = Boolean(anchorEl);
    const openLink = Boolean(anchorEl1);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const topScroll = () => {
        window.scrollTo(0, 0);
      }
    

    return (
        <div>
            <div className={classes.pageHeaderIconContainer}>
                {/* <div>
                    <img src={UserIcon} alt="User Icon" aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        className={classes.pageHeaderIcon}></img>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        className={classes.menuList}
                    >
                        <MenuItem onClick={handleClose}>Sign In</MenuItem>
                        <MenuItem onClick={handleClose}>Sign Up</MenuItem>
                    </Menu>
                </div> */}
                <div>
                    <img src={HamburgerIcon} alt="Hamburger Icon"
                        aria-controls={openLink ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openLink ? 'true' : undefined}
                        onClick={handleClick1}
                        className={classes.pageHeaderIcon}></img>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl1}
                        open={openLink}
                        onClose={handleClose1}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                        className={classes.menuList}
                    >
                        <Link to="/deterrent" className={classes.Links} onClick={topScroll}>
                        <MenuItem >Deterrent</MenuItem>
                        </Link>
                        <Link to="/smartContracts" className={classes.Links} onClick={topScroll}>
                        <MenuItem > Smart Contracts</MenuItem>
                        </Link>
                        <Link to="/roboticengagement" className={classes.Links} onClick={topScroll}>
                        <MenuItem >   Agile Copilot</MenuItem>
                        </Link>
                        <Link to="/paywatch" className={classes.Links} onClick={topScroll}>
                        <MenuItem >  Paywatch</MenuItem>
                        </Link>
                        <Link to="/applicant" className={classes.Links} onClick={topScroll}>
                        <MenuItem >  ATS</MenuItem>
                        </Link>
                        <Link to="/assessment" className={classes.Links} onClick={topScroll}>
                        <MenuItem >  Assessment</MenuItem>
                        </Link>
                        <Divider className={classes.dividerBar} />&nbsp;
                        <Link to="/gigwork" className={classes.Links} onClick={topScroll}>
                        <MenuItem >  Gig Work</MenuItem>
                        </Link>
                        <Link to="/earnhandsomereturn" className={classes.Links} onClick={topScroll}>
                        <MenuItem >   NFT & Caliber$</MenuItem>
                        </Link>
                        <Link to="/remoteFirst" className={classes.Links} onClick={topScroll}>
                        <MenuItem >   Remote First</MenuItem>
                        </Link>
                        <Link to="/oath" className={classes.Links} onClick={topScroll}>
                        <MenuItem >  Oath</MenuItem>
                        </Link>
{/* 
                        <Link className={classes.Links} to="/paywatch"><MenuItem >Paywatch</MenuItem></Link>
                        <Link className={classes.Links} to="/assessment"><MenuItem >Assessment</MenuItem></Link>
                        <Link className={classes.Links} to="/applicant"><MenuItem >Applicant Tracking</MenuItem></Link>
                        <Divider className={classes.dividerBar} />
                        <Link className={classes.Links} to="/performance"><MenuItem >Recruiter Management</MenuItem></Link>
                        <Link className={classes.Links} to="/hrsystems"><MenuItem >Integrations</MenuItem></Link>
                        <Link className={classes.Links} to="/reports"><MenuItem >Reports</MenuItem></Link>
                        <Link className={classes.Links} to="/adminstration"><MenuItem >Administration</MenuItem></Link> */}
                    </Menu>
                </div>
            </div>
        </div>
    );
};

MenuComponent.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MenuComponent);
