import React from 'react';
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, Typography, Box } from '@material-ui/core';
import page72 from '../../assets/images/2.webp';
import Page721 from '../../assets/images/Page7.webp';
import paywatch from '../../assets/images/paywatch.webp';
import { styled } from '@mui/material/styles';
import Layout from '../../components/Layout';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "20px 0px !important",
        },
    },
    gridItem: {
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            height: "auto",
        },
    },
    cardContiner: {
        height: "350px",
        display: "flex",
        justifyContent: "center",
        boxShadow: "none !important",
        width: "80%",
    },
    boxImg: {
        width: "550px",
        padding: "5px",
        [theme.breakpoints.only('xs')]: {
            width: "265px",
            height: "200px",
        },
    },
    boxContent: {
        width: "500px",
        padding: "5px",
        textAlign: "left",
        textJustify: "inter-word",
        [theme.breakpoints.only('xs')]: {
            width: "300px",
        },
    },
    boxContentText: {
        [theme.breakpoints.only('xs')]: {
            fontSize: "12px !important"
        },
    },
    iconImg: {
        width: "400px",
        paddingRight: "10px",
        [theme.breakpoints.only('xs')]: {
            width: "265px",
        },
    },
    gridSwitch: {
        display: "grid"
    },
    gridItem2: {
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('sm')]: {
            order: '2',
        },
        [theme.breakpoints.only('xs')]: {
            order: '2',
            height: "100px !important",
        },
    },
    gridItem3: {
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('sm')]: {
            order: '1',
        },
        [theme.breakpoints.only('xs')]: {
            order: '1',
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
})

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '1200px',
    maxHeight: '100%'
});


const Paywatch = (props) => {

    const { classes } = props;

    return (
        <Layout 
            horeImage={paywatch} 
            title="Paywatch - for software jobs at all levels"
            pageTitle="Tech Salary Comparison | Paywatch"
            pageDescription="Rezoomex's Paywatch provides insights into salary data for 28 technologies across 6 experience brackets."
            pageKeywords="Paywatch, Rezoomex Gig work platform, Tech salary comparison, Technology pay scales, IT salary trends, Software engineer salaries, IT job salary benchmarks, Salary ranges by tech skill"
        >
            <div className={classes.leftRightContainer} >
                <Grid container>
                    <Grid item className={classes.gridItem} lg={12} md={12} sm={12} xs={12} >
                        <div>
                            <div>
                                <Grid container>
                                    <Grid item className={classes.gridItem} lg={12} md={12} sm={12} xs={12}>
                                        <Card className={classes.cardContiner}>
                                            <Grid container spacing={0}>
                                                <Grid item className={classes.gridItem} lg={6} md={6} sm={12} xs={12} >
                                                    <Box className={classes.boxImg}>
                                                        <Img src={page72} className={classes.iconImg}></Img>
                                                    </Box>
                                                </Grid>
                                                <Grid item className={classes.gridItem} lg={6} md={6} sm={12} xs={12} >
                                                    <Box className={classes.boxContent}>
                                                        <Typography className={classes.boxContentText}>Paywatch is a free service based on samples taken for 28 technologies for 6 experience brackets for all important locations. The sample size is large enough to result in a bell-shaped distribution.</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                    <Grid item className={classes.gridItem} lg={12} md={12} sm={12} xs={12} >
                        <div>
                            <div>
                                <Grid container>
                                    <Grid item className={classes.gridItem} lg={12} md={12} sm={12} xs={12}>
                                        <Card className={classes.cardContiner}>
                                            <Grid container spacing={5} justifyContent="flex-start">
                                                <Grid item className={classes.gridItem2} lg={6} md={6} sm={12} xs={12} >
                                                    <Box className={classes.boxContent}>
                                                        <Typography className={classes.boxContentText}>Candlesticks show + or – 1 and 2 standard deviations around average for each company. The industry candlestick shows the same data for the entire sample. Top 9 companies based on the population within the sample are displayed.</Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item className={classes.gridItem3} lg={6} md={6} sm={12} xs={12} >
                                                    <Box className={classes.boxImg}>
                                                        <Img src={Page721} className={classes.iconImg} height={201}></Img>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Layout>
    )
}

Paywatch.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Paywatch);