import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AccomplishmentsImage from '../../assets/images/accomplishments.jpg';
import Layout from '../../components/Layout';
import config from "../../config/index";


const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    pageTitle: {
        textAlign: "left !important",
        fontWeight: "bold",
        fontSize: "20px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px !important",
            fontWeight: 'Bold !important',
            textAlign: "center !important"
        },
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },
    gridItem: {
        display: "flex !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important",
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    buttonsContainer: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "94%",
        transform: "translateX(-50%)",
        fontSize: "1vw",
        width: "10%",
        height: "5%",
        display: "flex",
        flexDirection: "row",
        backgroundColor: '#999',
        alignItems: "center",
        borderRadius: "8px",
        justifyContent: "center",
        [theme.breakpoints.up('lg')]: {
            fontSize: "12px !important"
        },
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonsStyle: {
        textDecoration: "none",
        color: "#fff",
        padding: "5%"
    },
    divider: {
        backgroundColor: "white !important"
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonCardContainer: {
        [theme.breakpoints.only('xs')]: {
            marginTop: "10px"
        },
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            marginBottom: "5px",
        },
    },
    bgContentText: {
        position: "absolute",
        fontWeight: "bold",
        fontFamily: "Proxima Nova",
        fontSize: "2.9vw",
        top: "4%",
        left: "50%",
        width: "100%",
        transform: "translateX(-50%)",
        [theme.breakpoints.up('lg')]: {
            fontSize: "40px !important",
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: "2.5vw",
        },
        [theme.breakpoints.only('sm')]: {
            fontSize: "2.3vw",
        },
    },
    userDetailsLink: {
        textDecoration: "none",
        color: "#3298CC",
    },
    titleText: {
        fontSize: "2.5vw",
        fontWeight: "500",
        marginTop: "1%",
        [theme.breakpoints.up('lg')]: {
            fontSize: "35px !important",
        },
    },
    listPoint: {
        color: "var(--primary-color)"
    },
    listPointDetails: {
        color: "#000"
    }
})

const Accomplishments = (props) => {

    const { classes } = props;

    return (
        <Layout horeImage={AccomplishmentsImage}>
            <div className={classes.pageParaText}>
                <ul>
                    <li className={classes.listPoint}>
                        <Typography><strong>Direct Relationships:</strong></Typography>
                        <Typography className={classes.listPointDetails}>We're dedicated to helping you forge strong and direct relationships with your team members in India eliminating unnecessary intermediaries. </Typography></li>
                    <br></br>
                    <li className={classes.listPoint}>
                        <Typography><strong>Growing Talent Pool:</strong></Typography>
                        <Typography className={classes.listPointDetails}>You'll connect with a rapidly expanding pool of Indian professionals seeking ethical employers. Our community already boasts {config.communityMembers} members, this community continues to grow with each passing day.</Typography>
                        <br></br>
                    </li>
                    <li className={classes.listPoint}>
                        <Typography><strong>Trustworthy Candidates:</strong></Typography><Typography className={classes.listPointDetails}>To date, {config.mintedMembers} members have minted their NFTs, providing you with the assurance that you're engaging with authentic candidates genuinely interested in joining your team. </Typography>
                        <br></br></li>
                    <li className={classes.listPoint}>
                        <Typography><strong>Ethical Teams:</strong> </Typography><Typography className={classes.listPointDetails}>You'll be introduced to professionals who prioritize trust and ethics in their interactions. Over {config.oathMembers} community members have pledged to uphold ethical behavior in their dealings with employers like you.</Typography>
                        <br></br></li>
                    <li className={classes.listPoint}>
                        <Typography><strong>Growing Together:</strong> </Typography><Typography className={classes.listPointDetails}>Guild Masters are on hand to guide these professionals, nurturing both their technical prowess and ethical values, ensuring they continuously enhance their value to employers.</Typography>
                        <br></br></li>
                </ul>
            </div>

        </Layout>
    );
}

Accomplishments.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Accomplishments);