import React from "react";
import { withStyles } from "@material-ui/core";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import { Box } from "@material-ui/core";
import mediumIcon from '../../assets/images/mediumIcon.svg';

const styles = (theme) => ({
  footer: {
    backgroundColor: "#000000",
    padding: "15px",
    width: "100%",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "end",
    gap: "6px",
    marginRight: "7rem",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center !important",
      marginRight: "0 !important",
    },
  },
  iconEnabled: {
    color: "#ffffff",
    fontSize: "32px !important",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center !important",
      marginRight: "0rem !important",
      fontSize: "20px !important"
    },
    cursor: "pointer",
  },
  iconDisabled: {
    color: "#ffffff",
    fontSize: "32px !important",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center !important",
      marginRight: "0rem !important",
      fontSize: "20px !important"
    },
  },
  iconMeidum: {
    height: "35px",
    [theme.breakpoints.down('sm')]: {
      height: "20px !important"
    },
  }
});
const Footer = (props) => {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <Box className={classes.iconContainer} spacing={3}>
        <TwitterIcon className={classes.iconEnabled} onClick={() => window.open('https://twitter.com/rezoomex', '_blank')} />
        <FacebookIcon className={classes.iconEnabled} onClick={() => window.open('https://www.facebook.com/rezoomex/', '_blank')} />
        <LinkedInIcon className={classes.iconEnabled} onClick={() => window.open('https://in.linkedin.com/company/rezoomex', '_blank')} />
        <YouTubeIcon className={classes.iconEnabled} onClick={() => window.open('https://www.youtube.com/channel/UC0_kcAniEfTRlz1faj0U-UQ?app=desktop', '_blank')} />
        <RssFeedIcon className={classes.iconEnabled} onClick={() => window.open('https://mailchi.mp/f1fa3618651d/rezoomex-newsletter-for-it-hiring-published-on-6226833', '_blank')} />
        <InstagramIcon className={classes.iconEnabled} onClick={() => window.open('https://www.instagram.com/rezoomex/?hl=en', '_blank')}/>
        <img src={mediumIcon} alt="Medium Icon" className={classes.iconMeidum}></img>
        <MailIcon className={classes.iconDisabled} />
      </Box>
    </footer>
  );
};

export default withStyles(styles, { withTheme: true })(Footer);
