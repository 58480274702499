import React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardMedia,
    Divider,
    Grid,
    withStyles
} from "@material-ui/core";
import MenuComponent from '../modules/rezoomexPages/MenuComponent';
import RezoomexLogo from '../modules/rezoomexPages/RezoomexLogo';
import LinksComponent from '../modules/rezoomexPages/LinksComponent';
import { Link } from 'react-router-dom';
import Footer from '../modules/rezoomexPages/Footer';
import { Helmet } from 'react-helmet';

const styles = (theme) => ({
    bgContentText: {
        position: "absolute",
        color: "white",
        bottom: "4%",
        left: "50%",
        transform: "translateX(-50%)",
        fontSize: "4.1vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "Bold",
        [theme.breakpoints.up('lg')]: {
            fontSize: "49px !important",
        },

    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.down('md')]: {
            margin: "10px 10px !important",
        },
    },
})

const Layout = (props) => {
    const { classes, horeImage, title, children, pageTitle, pageDescription, pageKeywords } = props;
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />
                <meta name="keywords" content={pageKeywords}></meta>
            </Helmet>
            <Grid container>
                <Grid container sx={{ position: 'relative' }}>
                    <Grid item lg={12} sm={12} md={12} xs={12} >
                        <Card className={classes.root}>
                            <div className={classes.pageHeader}>
                                <MenuComponent />
                            </div>
                            <div className={classes.bgImageContainer}>
                                <CardMedia className={classes.bgImg} component="img" image={horeImage} alt="Robotic Engagement" />
                                {title && <div className={classes.bgContentText}>
                                    {title}
                                </div>
                                }
                                <div className={classes.logoRezoomex}>
                                    <RezoomexLogo />
                                </div>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            <div className={classes.LinksComponentClass}>
                <Grid container >
                    <Grid container>
                        <Grid item lg={12} sm={12} md={12} xs={12}>
                            <LinksComponent />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.leftRightContainer} >
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className={classes.pageContent}>
                            <div className={classes.pageContent}>
                                {children}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    );
};
//props validation
Layout.propTypes = {
    classes: PropTypes.object,
    horeImage: PropTypes.string.isRequired,
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
};
export default withStyles(styles, { withTheme: true })(Layout);