import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardMedia, Divider, TextField, Typography } from '@material-ui/core';
import VirtualOathBg from '../../assets/images/virtualOath.webp';
import LinksComponent from './LinksComponent';
import RezoomexLogo from './RezoomexLogo';
import Footer from './Footer';
import MenuComponent from './MenuComponent';
import { Link } from 'react-router-dom';
import axios from 'axios';
import apiGatewayEndpoint from "../../config/index";
import Loading from './Loading';
import { Helmet } from 'react-helmet';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    pageTitle: {
        textAlign: "left !important",
        fontWeight: "bold",
        fontSize: "20px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px !important",
            fontWeight: 'Bold !important',
            textAlign: "center !important"
        },
    },
    gridItem: {
        display: "flex !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important",
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        marginBottom: "0px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            marginBottom: "5px",
        },
    },
    bgContentText: {
        position: "absolute",
        fontWeight: "bold",
        fontFamily: "Proxima Nova",
        fontSize: "4vw",
        top: "8%",
        left: "50%",
        width: "100%",
        transform: "translateX(-50%)",
        [theme.breakpoints.up('lg')]: {
            fontSize: "40px !important",
        },
    },
    titleText: {
        fontSize: "3.9vw",
        fontWeight: "500",
        marginTop: "27%",
        color: '#FFFFFF',
        [theme.breakpoints.up('lg')]: {
            fontSize: "35px !important",
            marginTop: "29%",
        },
    },
    gridFormContainer: {
        width: '900px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '0px 40px 0px 40px !important',
        },
        [theme.breakpoints.only('xs')]: {
            margin: "0px 0px 0px 0px !important",
        },
    },
    formTextField: {
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    gridItems: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
            paddingBottom: '15px'
        },
    },
    formButton: {
        backgroundColor: '#4A90E2 !important',
        color: '#FFFFFF !important',
        width: '150px !important',
        padding: '12px 10px'
    },
    apiSuccess: {
        fontWeight: "bold",
        fontSize: "18px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "15px !important",
            fontWeight: 'Bold !important',
        },
    },
    formFixed: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    loadingCircle: {
        zIindex: 5011,
        position: "fixed",
        padding: "0px",
        margin: "0px",
        top: "40%",
        textAlign: "center",
        border: "none",
        cursor: "default",
        opacity: 0.7,
        backgroundColor: "transparent",
    },
    input: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
})

const VirtualOath = (props) => {

    const { classes } = props;
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [userRequest, setUserRequest] = useState(false);
    const [error, setError] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isEmailRegistered, setIsEmailRegistered] = useState(false);
    const [isPhoneNoValid, setIsPhoneNoValid] = useState(true);
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const validPhoneNo = RegExp(/^[0-9]\d{9}$/);
    const saveData = () => {
        if (validEmailRegex.test(email.trim()) && validPhoneNo.test(phoneNo.trim())) {
            setUserRequest(true);
            const post_data = {
                email: email.trim(),
                fullName: name.trim(),
                telephone: phoneNo.trim()
            }
            axios.post(apiGatewayEndpoint.apiGatewayEndpoint + "/api/user/v1/website/oath", post_data, {
                headers: {
                    Authorization: null,
                    "Content-Type": "application/json"
                }
            })
                .then(data => {
                    setUserRequest(false);
                    if (data.status === 200) {
                        setError(false);
                    }
                })
                .catch(err => {
                    if (err.response.status === 422) {
                        console.log("User Inside Request Status 422")
                        setUserRequest(false);
                        setError(false);
                        setIsEmailRegistered(true);
                        setIsPhoneNoValid(true);
                    } else {
                        setError(true);
                        resetState();
                    }
                });
        } else {
            const trimmedEmail = email.trim();
            const trimmedPhoneNo = phoneNo.trim();
            setIsEmailValid(validEmailRegex.test(trimmedEmail));
            setIsPhoneNoValid(validPhoneNo.test(trimmedPhoneNo));
        }
    }

    const isSubmitDisabled = () => {
        return !(name.trim() && email.trim() && phoneNo.trim());
    }

    const resetState = () => {
        setName('');
        setEmail('');
        setPhoneNo('');
        setIsEmailValid(true);
        setIsPhoneNoValid(true);
    }

    return (
        <div >
            <div>
                <div>
                    <Helmet>
                        <title>Ethics in IT Industry, Take Oath | Rezoomex</title>
                        <meta name="description" content="Rezoomex takes great pride in developing ethics in Indian IT Industry. Take Oath now to become a member of an ethical community." />
                        <meta name="keywords" content="Rezoomex, Rezoomex Gig Work Platform, Ethics in IT Industry, Community of ehtical employees, Ethics in Indian IT industry, Oath for IT professional, Honesty, Moonlighting, Proxy Interviews, Oath"></meta>
                    </Helmet>
                    <Grid container>
                        <Grid container sx={{ position: 'relative' }}>
                            <Grid item lg={12} sm={12} md={12} xs={12} >
                                <Card className={classes.root}>
                                    <div className={classes.pageHeader}>
                                        <MenuComponent />
                                    </div>
                                    <div className={classes.bgImageContainer}>
                                        <CardMedia className={classes.bgImg} component="img" image={VirtualOathBg} alt="Virtual Oath" />
                                        <div className={classes.bgContentText}>
                                            Solemn Oath !
                                            <div className={classes.titleText}>
                                                Community of ethical software professionals
                                            </div>
                                        </div>
                                        <div className={classes.logoRezoomex}>
                                            <RezoomexLogo />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className={classes.LinksComponentClass}>
                <Grid container >
                    <Grid container>
                        <Grid item lg={12} sm={12} md={12} xs={12}>
                            <LinksComponent />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.leftRightContainer} >
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <div className={classes.pageContent}>
                            <div className={classes.pageContent}>
                                <div className={classes.fixHeight}>
                                    <Typography className={classes.pageParaText}><strong>I solemnly pledge</strong> to uphold the highest standards of honesty, truthfulness, and integrity as an Indian Software professional. I take great pride in being a member of a profession that has contributed significantly to our country's growth and prosperity.</Typography>
                                    <Typography className={classes.pageParaText}><strong>I understand</strong> that working remotely from home or elsewhere comes with higher responsibility of practicing transparency, honesty, and professional ethics to win the trust of employers and clients.</Typography>
                                    <Typography className={classes.pageParaText}><strong>I recognize</strong> that unethical practices such as proxy interviews, moonlighting, no show, absconding, and quiet quitting can tarnish the image of our profession. Therefore, I pledge to be vigilant against such practices and actively work towards eradicating those from our industry to create the trust needed to increase the adoption of remote working, self-organization, flexibility, and autonomy.</Typography>
                                    <Typography className={classes.pageParaText}><strong>I pledge</strong> to actively participate in this community’s discussions to evolve the code of conduct to be followed by everyone. In cases where there is no consensus, I will continue to debate and work towards creating a consensus-based solution.</Typography>
                                    <Typography className={classes.pageParaText}><strong>I understand</strong> that my actions and behaviour reflect not only on me but on my entire profession. Therefore, I will always strive to act in a manner that upholds the highest standards of professionalism and integrity, both in my personal and professional life.</Typography>
                                    <Typography className={classes.pageParaText}><strong>As a symbol</strong> of my commitment to this oath, I am submitting my resume- note that it represents not just my skillset, but my dedication to upholding the highest ethical standards in my profession.</Typography>
                                </div>
                            </div>
                            <div className={classes.formFixed}>
                                {
                                    userRequest ? <Loading className={classes.loadingCircle} size={70} /> : null
                                }
                                {
                                    (error === false && !isEmailRegistered) && (<div className={classes.apiMessageContainer}>
                                        <Typography variant='h5' className={classes.apiSuccess}>Thank you for pledging to uphold professionalism and integrity in the Indian software industry.</Typography>
                                        <Typography variant='h5' className={classes.apiSuccess}>Your commitment to ethical practices is truly appreciated.</Typography>
                                    </div>)
                                }
                                {
                                    (error === false && isEmailRegistered) && (<div className={classes.apiMessageContainer}>
                                        <Typography variant='h5' className={classes.apiSuccess}>It seems that you have already taken the oath.</Typography>
                                        <Typography variant='h5' className={classes.apiSuccess}> Thank you for your participation and your continued support in promoting ethical behavior in our industry.</Typography>
                                    </div>)
                                }
                                {
                                    error === true && (<div>
                                        <Typography variant='h5' className={classes.apiSuccess}>We are facing some technical issue.<br /> Please try after sometime.</Typography>
                                    </div>)
                                }
                                {error === null && <Grid container className={classes.gridFormContainer}>
                                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.gridItems}>
                                        <div className={classes.formTextField}>
                                            <TextField
                                                id="first-name"
                                                label="Full Name"
                                                variant="outlined"
                                                autoComplete='off' value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                InputLabelProps={{
                                                    className: classes.inputName,
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.gridItems}>
                                        <div className={classes.formTextField}>
                                            <TextField
                                                fullWidth
                                                id="email"
                                                label="Email"
                                                variant="outlined"
                                                autoComplete='off'
                                                helperText={isEmailValid ? "" : "Email is not valid"}
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.gridItems}>
                                        <div className={classes.formTextField}>
                                            <TextField
                                                fullWidth
                                                id="phone-no"
                                                label="Mobile Number"
                                                className={classes.input}
                                                variant="outlined"
                                                autoComplete='off'
                                                type='number'
                                                helperText={isPhoneNoValid ? "" : "Mobile number is not valid"}
                                                value={phoneNo}
                                                onChange={(e) => setPhoneNo(e.target.value)}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={12} className={classes.gridItems}>
                                        <Button
                                            variant="contained"
                                            color='primary'
                                            className={classes.formButton}
                                            disabled={isSubmitDisabled()}
                                            onClick={() => saveData()}
                                        >
                                            Take Oath
                                        </Button>
                                    </Grid>
                                </Grid>}
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.footer}>
                <Footer />
            </div>

        </div>
    )
}

VirtualOath.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(VirtualOath);