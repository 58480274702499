import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import WorkingremotelyImage from '../../assets/images/Workingremotely.webp';
import Layout from '../../components/Layout';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },

    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText: {
        textAlign: "left",
        padding: '10px',
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            marginBottom: "5px",
        },
    },
})

const Deterrent = (props) => {

    const { classes } = props;

    return (
        <Layout
            horeImage={WorkingremotelyImage}
            title={<Typography style={{ fontSize: "unset", fontFamily: "unset", fontWeight: "unset" }}>Monkey bunsiness in your remote teams?<br></br>Try this deterrent!</Typography>}
            pageTitle="Deterrent for Remote Teams | Rezoomex"
            pageDescription="Monkey business in your remote teams? See how Rezoomex Gig Work Platform helps achieve maximum productivity from your remote teams."
            pageKeywords="Rezoomex Gig Work Platform, Rezoomex, Deterrent for remote teams, Remote Work, Smart Contracts in Gig work, UPI Payments for Gig work, CBDC in gig work, Rezoomex agile copilot"
        >
            <div className={classes.pageContent}>
                <Typography className={classes.pageParaText}>Remote work is here to stay. Almost all software development teams are distributed. However, it comes with its own set of issues, especially when the remote workers are in India. Attrition has always been a problem, and there’s no certainty that a candidate will join on the committed date. Other issues include moonlighting, bait and switch, and proxy interviews. Some teams face engagement issues, ranging from muted video calls to team members completely absconding.</Typography>
                <Typography className={classes.pageParaText}>If these problems are not currently occurring in your India-based teams, consider that they could arise in the future. Many companies have tried implementing policies to prevent these malpractices with varying degrees of success. In most cases, these policies lead to employees perceiving the company as a “big brother” policing their activities.</Typography>
                <Typography className={classes.pageParaText}>The situation is about to improve. India has unique advantages – with UPI payments and CBDC, <i>smart contracts</i> are practical and viable in India. Continue reading to understand the Rezoomex solution – an Indian solution for Indian problems.</Typography>
                <Typography className={classes.pageParaText}>Professionals in the Rezoomex NFT marketplace can enter into smart contracts for full-time jobs, side projects, and tech tasks. In addition to this, they receive multiple benefits from their <i>NFTs and Caliber$</i>. These benefits come in the form of both money and reputation. They have a lot to lose if they violate the code of conduct. Check out the oath they have taken for a better understanding of this deterrent.</Typography>
                <Typography className={classes.pageParaText}>Additionally, our <i>Agile Copilot</i> can help you engage your teams and achieve the desired behavior. So, you can expect a dramatic improvement in overall performance with consistency and predictability</Typography>
            </div>
        </Layout>
    )
}

Deterrent.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Deterrent);