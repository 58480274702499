import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => ({
  root: {
    flexGrow: 1,
  },
  loadingContainer: {
    width: "100%",
    height: "100%",
    top: "0px",
    left: "0px",
    position: "fixed",
    opacity: 0.7,
    backgroundColor: "#a7a7a7",
    zIndex: 5000,
    textAlign: "center",
    margin: "0px",
    padding: "0px",
    cursor: "default",
  }
});

function Loading(props) {
  const { classes } = props;
  return (
    <div className={classes.loadingContainer}>
      <CircularProgress className={classes.root} {...props} />
    </div>
  );
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loading);
